var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.AppModule.loggedIn)?_c('v-app-bar',{attrs:{"color":_vm.AppModule.appBarColor,"elevate-on-scroll":"","scroll-target":"#main-app-content","src":_vm.AppModule.appBarImage,"height":_vm.AppModule.appBarHeight,"prominent":!!
    (
      _vm.AppModule.appBarExtensionComponent ||
      _vm.AppModule.appBarImage
    ),"shrink-on-scroll":!!
    (
      _vm.AppModule.appBarExtensionComponent ||
      _vm.AppModule.appBarImage
    ),"fade-img-on-scroll":!!_vm.AppModule.appBarImage,"app":""},scopedSlots:_vm._u([(_vm.AppModule.appBarExtensionComponent)?{key:"extension",fn:function(){return [_c('v-container',[(_vm.AppModule.appBarExtensionComponent)?_c('v-row',[_c(_vm.AppModule.appBarExtensionComponent,{tag:"component"})],1):_vm._e()],1)]},proxy:true}:null,(_vm.isMobile && _vm.AppModule.appBarImage)?{key:"img",fn:function(ref){
    var props = ref.props;
return [_c('v-img',_vm._b({attrs:{"contain":"","aspect-ratio":2}},'v-img',props,false))]}}:null],null,true)},[(!_vm.isMobile)?_c('router-link',{attrs:{"text":"","to":"/applicants"}}):(!_vm.isListPath && _vm.isMobile)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('/applicants')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1):_vm._e(),[_c('v-img',{staticClass:"mx-auto",attrs:{"contain":"","max-width":"150","src":require("../../assets/logo_red.svg")},on:{"click":function($event){return _vm.goToHome()}}}),(_vm.isListPath)?_c('ApplicantSearchBar'):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mainMenu",attrs:{"icon":"","color":"primary","data-cy":"menu"},on:{"click":function($event){return _vm.$emit('open-drawer')}}},[_c('v-icon',[_vm._v("mdi-menu")]),_vm._v(" Menü ")],1)]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }