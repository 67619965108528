


















































































import PDFViewerMixin from '@/mixins/PDFViewerMixin.mixin.vue';
import {
  Container, MetaContainer,
} from '@/types';
import {
  ASSESSMENT_EXTENSION, BASE_URL, UFORM,
} from '@/utils/requestUrl';
import { Component, Mixins } from 'vue-property-decorator';
import PdfVue from '@/components/base/PdfVue.vue';

@Component({
  components: { PdfVue  },
  mixins: [PDFViewerMixin],
})
export default class ApplicantTestUform
  extends Mixins(PDFViewerMixin) {
    container:Container<MetaContainer> = {};
    file: File|null = null;
    files: any = [];
    async created() {
      const { id } = this.$route.params;
      const res = await this.axios.get<Container<MetaContainer>>(`${BASE_URL + ASSESSMENT_EXTENSION}/${id + UFORM}`, {withCredentials: true});
    
      
     this.container = res.data;
    }
}
