











import ApplicantDetailNavigation from "@/components/applicant/detail/ApplicantDetailNavigation.vue";
import DefaultRouterView from "@/components/core/DefaultRouterView.vue";
import ApplicantRatingSheet from "@/components/applicant/detail/ApplicantRatingSheet.vue";
import { Component, Vue } from "vue-property-decorator";
import { Applicant } from "@/types";
import { mapApplicant, mapApplicantContact, mapAttachments } from "@/utils/backendTypeConverter";
// eslint-disable-next-line import/no-cycle
import { ApplicantModule } from "@/store";
import { APPLICANT_URL, CONTACT_EXTENSION } from "@/utils/requestUrl";

@Component({
  components: {
    DefaultRouterView,
    ApplicantRatingSheet,
    ApplicantDetailNavigation
  }
})
export default class ApplicantDetail extends Vue {
  ApplicantModule = ApplicantModule;

  async getApplicantDetails() {
    try {
      const { id } = this.$route.params;
      const response = await this.axios.get(
        `${APPLICANT_URL}/${id}${CONTACT_EXTENSION}`,
        { withCredentials: true }
      );
      response.data.data.id = Number(id);
      const result: Applicant = {
        ...mapApplicantContact(response.data.data),
        ...mapApplicant(response.data.data),
        attachments: mapAttachments(response.data.attachments)
      } as Applicant;
      ApplicantModule.SET_APPLICANT(result);
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  }

  created() {
    this.getApplicantDetails();
  }
}
