import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#fa1923",
        secondary: "#e6e6e6",
        accent: "#f2f2f2",
        error: "#b71c1c"
      }
    }
  }
});
