























































































































import { LOGIN } from '@/router/names';
import PasswordRule from '@/mixins/PasswordRule.mixin.vue';
import {
  Component, Mixins,
} from 'vue-property-decorator';
import { FORGOT_PASSWORD_RULES, REVERT_PASSWORD } from '@/utils/requestUrl';
// eslint-disable-next-line import/no-cycle
import { AppModule } from '@/store';

@Component({
  mixins: [PasswordRule],
})
export default class ForgotPassword extends Mixins(PasswordRule) {
  created() {
    PasswordRule.urlPost = `${FORGOT_PASSWORD_RULES}/${this.$route.query.token}`;
    PasswordRule.urlGet = REVERT_PASSWORD;
    PasswordRule.getObjectPost = () => ({
      token: this.$route.query.token,
      password: this.password,
    });
    this.getPasswordRules();
    AppModule.SET_APP_BAR_TITLE('');
  }

  handleDialogBtn() {
    if (this.loginStatus && this.loginStatus < 300) this.closeDialog();
    else this.dialog = false;
  }

  closeDialog() {
    this.$router.push({ name: LOGIN });
  }

  /**
     * @method: changepassword
     * @description: Check change password form and
      sends data together with the jwt from the store to the server
     * */
}
