













































































































































import Widget from "@/mixins/Widget.mixin.vue";
import { Position, result } from "@/types";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { ApplicantModule, AppModule } from "@/store";
import {  APPLICANTS_SORT, RANKINGLIST } from "@/utils/requestUrl";
@Component({
  components: {},
  mixins: [Widget],
  
})
export default class ApplicantSearchBar extends Mixins(Widget) {

  showMenu = false;

  search = ApplicantModule.search;

  positions = ApplicantModule.positions[0];

  applicantSort: result[] = ApplicantModule.sorting;

  toEvaluate: boolean = ApplicantModule.toEvaluate;

  showArchive: boolean = ApplicantModule.showArchive;

  isCardView: boolean = AppModule.isCardView;

  rankingUrl: string = "";
  
  rankingArray = [];

  viewToggle: number = Number(!this.isCardView);

  /**
   * Switches between card and table views.
   * The v-btn-toggle uses "viewSwitch" as v-model and assigns the index of the
   * button that was clicked to it.
   */
  @Watch("viewToggle")
  toggleView() {
    this.isCardView = this.viewToggle === 0;
    AppModule.SET_IS_CARD_VIEW(this.isCardView);
  }

  get hasRankinglist(){
    if (ApplicantModule.idOfPosition !== null) {
      this.getRankinglist(ApplicantModule.idOfPosition);
    }
    return ApplicantModule.hasRankinglist;
  }

  // eslint-disable-next-line class-methods-use-this
  get openPositionsAsArray() {
    return Object.values(ApplicantModule.openPositions).map(
      (pos: Position) => pos.position
    );
  }

  private searchTimeout: any;
  private isSearchRunning = false;

  setAll() {
    if (this.isSearchRunning) return;

    ApplicantModule.SET_TO_EVALUATE(this.toEvaluate);
    ApplicantModule.SET_SEARCH(this.search);
    ApplicantModule.SET_SHOW_ARCHIVE(this.showArchive);
    ApplicantModule.SET_POSITIONS([this.positions]);
    this.showMenu = false;
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.isSearchRunning = true;
      //App Update needed muss entweder ausgelagert werden oder doch AppModule einbauen
      AppModule.SET_APP_UPDATE_NEEDED(!AppModule.appUpdateNeeded);
      this.isSearchRunning = false;
    }, 1000);
  }

  toggleArchiveEvaluate(value: boolean, field: string) {
    this[field] = !value;
  }

  openRanking(url: string) {
    /*if (this.rankingUrl == "list") {
      console.log(this.selectedRankings);
    } else if (this.rankingUrl == null) {
        return;
    } else {
      window.open(this.rankingUrl, "_blank");
    }*/
    window.open(url, "_blank");
  }

  async sortCallback() {
    var body = ApplicantModule.sorting;
    try {
      const { id } = this.$route.params;
      const response = await this.axios.post(
        `${APPLICANTS_SORT}`,
         ApplicantModule.sorting ,
        { withCredentials: true }
      ).then(response => {
        if (response.status === 204) {
          console.log("updated");
          //macht das sinn
         // AppModule.SET_APP_PAGE_TABLE(1);
         
          AppModule.SET_APP_UPDATE_NEEDED(!AppModule.appUpdateNeeded);
        }
      });
      // this.sorting =  Object.values(response.data);
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  }

  get minWidth() {
    if (this.isMobile) {
      return "100%";
    }
    return "600px";
  }

   get minRankinlistWidth() {
    if (this.isMobile) {
      return "100%";
    }
    return "200px";
  }

  /**
   * Sends a request
   */
  async getRankinglist(positionId: number | null) {
    try {
      const { id } = this.$route.params;
      const response = await this.axios.get(`${RANKINGLIST}/${positionId}`, {
        withCredentials: true,
      });
      this.rankingArray = Object.values(response.data);
      if (this.rankingArray.length > 1) {
        this.rankingUrl = "list";
      } else {
        this.rankingUrl = response.data[0].url;
      }
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  }
}
