























































import WidgetMixin from "@/mixins/Widget.mixin.vue";

import { Component, Mixins } from "vue-property-decorator";
import { APPLICANT_URL, NOTE_EXTENSION } from "@/utils/requestUrl";

import Snackbar from "@/mixins/Snackbar.mixin.vue";
import { ApplicantModule } from "@/store";

@Component({
  components: {},
  mixins: [WidgetMixin, Snackbar]
})
export default class ApplicantDetailNotice extends Mixins(WidgetMixin, Snackbar) {
  dialog = false;

  note: string | undefined = ApplicantModule.applicant?.note;

  created() {
    //Hat Chris das gelöscht?
    this.note = ApplicantModule.applicant?.note;
  }

  saveNote() {
    if (!this.note || this.note === "") {
      return;
    }
    // eslint-disable-next-line no-unused-vars
    const { id } = this.$route.params;
    const response = this.axios.post(
      `${APPLICANT_URL}/${id}${NOTE_EXTENSION}`,
      { note: this.note },
      { withCredentials: true }
    );
    response.then((response) => {
      this.snackbar = true;
      this.loginStatus = response.status;
      this.snackbarMessage = "Notiz wurde abgespeichert!";

      const applicant: any = { ...ApplicantModule.applicant, note: this.note };
      ApplicantModule.SET_APPLICANT(applicant);
    }).catch(() => {
      this.snackbar = true;
      this.loginStatus = 500;
      this.snackbarMessage = "Notiz konnte nicht gespeichert werden!";
    });
  }
}
