























































































































































































import { APPLICANT_DETAIL } from "@/router/names";
import { ApplicantModule } from "@/store";
import { Applicant, Rated } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import getAge from "../../utils/getAge";

@Component
export default class ApplicantCard extends Vue {
  @Prop(Object) applicant!: Applicant;

  Rated: any;

  showNote: boolean = false;

  /**
   * TODO: variable is being used in different ways depending on whether the rating is finished; refactor (use 2 variables instead?)
   * @see created()
   */
  showRating: boolean | undefined;

  ratingPoints: number | string | undefined;

  /**
   * Sets the default values for the component. Necessary because initializing them above doesn't work for unknown reasons.
   * Also assigns the rating points depending on the type of rating being used and if the rating is finished.
   */
  created() {
    this.Rated = Rated;
    this.showRating = true;
    this.showNote = false;

    /**
     * TODO: works but is pretty obscure; expand / make purpose of variables more obvious
     * @see showRating
     */
    if (!this.applicant.evaluation) {
      this.ratingPoints = this.applicant.rating.points;
    } else if (this.applicant.evaluation?.startsWith("*")) {
      this.ratingPoints = this.applicant.evaluation.length;
    } else {
      this.ratingPoints = this.applicant.evaluation;
      this.showRating = false;
    }
  }

  /**
   * Determines whether to show the note button.
   * The note button should be shown if the note isn't empty.
   */
  get showNoteButton(): boolean {
    return this.applicant.note !== "";
  }

  get occupation() {
    if (this.applicant.occupation) {
      const limit = this.$vuetify.breakpoint.xs ? 65 : 90;
      const res = String(this.applicant.occupation.substring(0, limit));
      if (limit <= res.length) {
        return res.trim().concat("...");
      }
      return res;
    }
    return "";
  }

  // eslint-disable-next-line class-methods-use-this
  handleBtn() {
    if (this.showNote) {
      this.showNote = false;
    } else {
      this.showNote = true;
      setTimeout(() => {
        this.showNote = false;
      }, 5000);
    }
  }

  showDetails(applicantId: string) {
    ApplicantModule.SET_APPLICANT_ID(applicantId);
    this.$router.push({
      name: APPLICANT_DETAIL,
      params: { id: applicantId }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getSexSymbol(salutation: string) {
    if (salutation === "Frau") return "w";
    if (salutation === "Herr") return "m";
    return "d";
  }

  // eslint-disable-next-line class-methods-use-this
  getAge(birthday: string) {
    return getAge(birthday);
  }
}
