


































import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    VuePdfApp
  }
})
export default class PdfVue extends Vue {
  config = { toolbar: false };
  @Prop() value!: boolean;
  @Prop({ default: null }) pdfSource!: string;
  @Prop({ default: undefined }) postBody: any;

  res: any;
  spdf: any;

  @Watch("pdfSource", { immediate: true })
  onPdfSourceChanged(pdfSource: String) {
    if (pdfSource) {
      this.res = this.base64ToArrayBuffer(this.pdfSource);
      this.spdf = this.res;
    }
  }

  base64ToArrayBuffer(base64: string) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
}
