




































































































































import PDFViewerMixin from '@/mixins/PDFViewerMixin.mixin.vue';
import PdfVue from '@/components/base/PdfVue.vue';
import {
  Container, LabelRequired, MetaContainer, PdfOption,
} from '@/types';
import {
  APPLICANT_URL,
  ASSESSMENT_EXTENSION, BASE_URL, GEVA, PDF_EXTENSION,
} from '@/utils/requestUrl';
import { Component, Mixins } from 'vue-property-decorator';


@Component({
  components: { PdfVue },
  mixins: [PDFViewerMixin],
})
export default class ApplicantTestGeva
  extends Mixins(PDFViewerMixin) {
    container:Container<MetaContainer> = {};
    file: File|null = null;
    files: any = [];
    open={ open: false };
    async created() {
      const { id } = this.$route.params;
      const res = await this.axios.get<Container<MetaContainer>>(`${BASE_URL + ASSESSMENT_EXTENSION}/${id + GEVA}`);
      this.files = res.data[0].files!;
     this.container = res.data;
         this.generateFileUrl = `${APPLICANT_URL}/${id}${PDF_EXTENSION}`;
      this.axios.get<PdfOption>(
        this.generateFileUrl, {withCredentials: true}
      )
        .then((res) => {
          this.options = res.data;
        });
    }

    pdfView(isDownload:boolean) {
      this.options.attachments = Object.fromEntries(Object.entries(this.options.attachments)
        .map((entry) => [entry[0],
        { label: entry[1].label, required: false }as LabelRequired]))as any;

      this.options.options = Object.fromEntries(Object.entries(this.options.options)
        .map((entry) => [entry[0],
        { label: entry[1].label, required: false }as LabelRequired]))as any;
      this.options.attachments.checkix.required = true;
      this.postOptionsPdf(isDownload);
    }
}
