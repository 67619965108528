



































































































































































































































































import WidgetMixin from "@/mixins/Widget.mixin.vue";
import PdfVue from "@/components/base/PdfVue.vue";
import { Component, Mixins, Ref } from "vue-property-decorator";

import PDFViewerMixin from "@/mixins/PDFViewerMixin.mixin.vue";
import { APPLICANT_URL, PDF_EXTENSION } from "@/utils/requestUrl";
import { LabelRequired, PdfOption } from "@/types";
import { ApplicantModule } from "@/store";
import _ from "lodash";

@Component({
  components: {
    PdfVue
  },
  mixins: [WidgetMixin, PDFViewerMixin]
})
export default class ApplicantDetailAttachments extends Mixins(WidgetMixin, PDFViewerMixin) {
  @Ref("attachments") attachments!: HTMLElement;

  ApplicantModule = ApplicantModule;

  allSwitch = true;

  generateDialog = false;

  async created() {
    const { id } = this.$route.params;

    this.generateFileUrl = `${APPLICANT_URL}/${id}${PDF_EXTENSION}`;

    await this.axios.get<PdfOption>(this.generateFileUrl, { withCredentials: true }).then((res) => {
      this.options = res.data;
    });
  }

  get showGeneratePdfButton() {
    return !(_.isEmpty(this.options.options) && _.isEmpty(this.options.attachments));
  }

  get showDownloadButton(): boolean {
    return this.ApplicantModule.applicant?.attachments.allowDownload === true;
  }

  // eslint-disable-next-line class-methods-use-this
  getTypeImage(icon: string) {
    if (icon.toLowerCase() === "file-pdf") {
      return "file-pdf-outline";
    }
    if (icon === "xlsx") {
      return "file-excel-outline";
    }
    if (icon === "word") {
      return "file-word-outline";
    }
    return "file-document";
  }

  // eslint-disable-next-line class-methods-use-this
  getDocType(icon: string) {
    if (icon.toLowerCase() === "file-pdf") {
      return "PDF-Datei";
    }
    if (icon === "xlsx") {
      return "file-excel-outline";
    }
    if (icon === "word") {
      return "file-word-outline";
    }
    return "file-document";
  }

  selectAll(selectOptions: boolean = true) {
    if (selectOptions) {
      this.options.options = Object.fromEntries(
        Object.entries(this.options.options).map((entry) => [
          entry[0],
          { label: entry[1].label, required: this.allSwitch } as LabelRequired
        ])
      ) as any;
    }
    this.options.attachments = Object.fromEntries(
      Object.entries(this.options.attachments).map((entry) => [
        entry[0],
        { label: entry[1].label, required: this.allSwitch } as LabelRequired
      ])
    ) as any;
    this.allSwitch = !this.allSwitch;
  }

  postAllAttachments(isDownload: boolean) {
    this.selectAll(false);
    this.postOptionsPdf(isDownload);
  }

  get attachmentWidth() {
    return this.attachments;
  }
}
