






import Settings from "@/components/account/Settings.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: { Settings } })
export default class SettingsPage extends Vue {

}
