













import { Component, Mixins } from "vue-property-decorator";
import ApplicantTestAlpha from "@/components/applicant/detail/Tests/ApplicantTestAlpha.vue";
import ApplicantTestCute from "@/components/applicant/detail/Tests/ApplicantTestCute.vue";
import ApplicantTestUform from "@/components/applicant/detail/Tests/ApplicantTestUform.vue";
import ApplicantTestHogrefe from "@/components/applicant/detail/Tests/ApplicantTestHogrefe.vue";
import ApplicantTestGeva from "@/components/applicant/detail/Tests/ApplicantTestGeva.vue";
import ApplicantTestCheckix from "@/components/applicant/detail/Tests/ApplicantTestCheckix.vue";
import WidgetMixin from "@/mixins/Widget.mixin.vue";

@Component({
  components: {
    ApplicantTestAlpha,
    ApplicantTestHogrefe,
    ApplicantTestUform,
    ApplicantTestCute,
    ApplicantTestGeva,
    ApplicantTestCheckix
  },
  mixins: [WidgetMixin]
})
export default class ApplicantTests extends Mixins(WidgetMixin) {

}
