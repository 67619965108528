
































































































import ApplicantCard from "@/components/applicant/ApplicantCard.vue";
import InfiniteLoading from "vue-infinite-loading";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Applicant, Container, LabelValue, Position, Rated, result } from "@/types";
import { AxiosResponse } from "axios";
// eslint-disable-next-line import/no-cycle
import { ApplicantModule, AppModule } from "@/store";
import { APPLICANTS_CARD_URL, APPLICANTS_LIST_URL, JOBS_EXTENSION, RANKINGLIST, APPLICANTS_SORT } from "@/utils/requestUrl";
import { mapApplicants } from "@/utils/backendTypeConverter";
import { APPLICANT_DETAIL } from "@/router/names";
import _ from "lodash";
@Component({
  components: {
    ApplicantCard,
    InfiniteLoading
  }
})
export default class ApplicantList extends Vue {
  AppModule = AppModule;
  ApplicantModule = ApplicantModule;
  isLoadingCardView = false;
  isLoadingTableView = false;
  // welche Variablen werden gebraucht
  search = "";
  pages = 1;
  options:any = {};
  sortableList: any;
  noOfPages = 1;
  @Watch("options")
  onApiCall() {
    const { sortBy, sortDesc, page } = this.options;
    this.prepareSortObject(sortBy, sortDesc, page);
  }
  showDetails(applicantId: string) {
    ApplicantModule.SET_APPLICANT_ID(applicantId);
    this.$router.push({
      name: APPLICANT_DETAIL,
      params: { id: applicantId }
    });
  }
  /**
   * If this.change gets update then set local search, vStore APP_Page and IDS_ONLINE_FILTER
   * After resetting vStore parameters the this.getApplicants() is fired
   */
  @Watch("change", { deep: true })
  onUpdateNeededChange() {
    this.search = ApplicantModule.search;
    AppModule.SET_APP_PAGE(1);
    AppModule.SET_APP_PAGE_TABLE(1);
    this.pages = 1;
    ApplicantModule.CLEAR_APPLICANTS();
    ApplicantModule.CLEAR_APPLICANTS_TABLE_VIEW();
    this.getApplicantsForCard();
    this.getApplicantsForTable();
  }
  /**
   * If the Filter is applied this.change gets updated
   */
  get change() {
    return AppModule.appUpdateNeeded;
  }
  /**
   * This method just returns all applicants that are in ApplicantStore
   * TODO: implement filter
   */
  get getFilteredApplicants(): Applicant[] {
    return ApplicantModule.applicants;
  }
  created() {
    this.getSortable();
    if (!ApplicantModule.applicants.length) {
      this.getJobList();
      this.getApplicantsForCard();
    }
    if (!ApplicantModule.applicantsTableView.length) {
      this.getApplicantsForTable();
    }
    this.setTitle(ApplicantModule.totalApplicants);
    this.noOfPages = this.calculatePagesCount(AppModule.appPageSizeTable, ApplicantModule.totalApplicants);
  }
  /**
   * Sends a request with showArchive and toEvaluate to the server.
   * Sets OPEN_POSITION in vStore to the response of the server
   */
  async getJobList() {
    this.axios
      .post(
        JOBS_EXTENSION,
        { toEvaluate: 1, pageNumber: 1, pageSize: 30 },
        { withCredentials: true }
      )
      .then((response: AxiosResponse<Container<LabelValue<Position>>>) => {
        ApplicantModule.SET_OPEN_POSITIONS(response.data);
      });
  }
  /**
   * TODO
   */
  async getRankinglist() {
    try {
      const { id } = this.$route.params;
      const response = await this.axios.get(`${RANKINGLIST}/${AppModule.user}`, {
        withCredentials: true,
      });
      console.log(response);
      /* const result: Applicant = {
              ...ApplicantModule.applicant,
              development: response.data,
            } as Applicant;
            ApplicantModule.SET_APPLICANT(result);*/
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  }
  /**
   * Gets fired on the defined scroll event of infinite-loading component
   */
  async infiniteHandler($state: any) {
    this.getApplicantsForCard(true)
      .then((response) => {
        if (!response.data) {
          $state.complete();
          return;
        }
        if (response.data && Object.entries(response.data.list).length === AppModule.appPageSize) {
          AppModule.SET_APP_PAGE(AppModule.appPage + 1);
          $state.loaded();
        } else {
          $state.complete();
        }
      })
      .catch(() => {
        $state.complete();
      });
  }
  /**
   * Sends Request for all Applicants that match the given filter parameter of vStore.
   * Requires correct page number in vStore
   * @returns Promise of AxiosResponse<any>
   */
  async getApplicantsForTable() {
    this.isLoadingTableView = true;
    try {
      const result = await this.axios.post(
        APPLICANTS_LIST_URL,
        {
          pageNumber: AppModule.appPageTable,
          pageSize: AppModule.appPageSizeTable,
          positionId: ApplicantModule.idOfPosition,
          search: ApplicantModule.search,
          showArchive: ApplicantModule.showArchive ? "1" : "0",
          toEvaluate: ApplicantModule.toEvaluate ? "1" : "0"
        },
        { withCredentials: true }
      );
      let listOfObjects = Object.keys(result.data.list).map((key) => {
        return result.data.list[key];
      });
      var listOfDetails = [];
      for (var prop in listOfObjects) {
        listOfDetails.push(listOfObjects[prop].details);
      }
      result.data.headings.push({ text: "Bewertung", value: "evaluation" });
      result.data.headings.push({ text: "Notiz", value: "note" });

      // determine which columns should be sortable by comparing the headers to the sortable list
      for (let i in result.data.headings) {
        let heading  = result.data.headings[i];
        let sortable = false;
        for (let j in this.sortableList) {
          if (heading.value === this.sortableList[j].reference) {
            sortable = true;
            break;
          }
        }
        heading.sortable = sortable;
        result.data.headings[i] = heading;
      }

      result.data.list = listOfDetails;
      this.updateState(result);
      this.isLoadingTableView = false;
      return { headings: result.data.headings, list: listOfDetails };
    } catch (error) {
      this.isLoadingTableView = false;
      throw new Error();
    }
  }
  async getApplicantsForCard(isInfiniteHandler: Boolean = false) {
    try {
      if (!isInfiniteHandler) {
        this.isLoadingCardView = true;
      }
      const result = await this.axios.post(
        APPLICANTS_CARD_URL,
        {
          pageNumber: AppModule.appPage,
          pageSize: AppModule.appPageSize,
          positionId: ApplicantModule.idOfPosition,
          search: ApplicantModule.search,
          showArchive: ApplicantModule.showArchive ? "1" : "0",
          toEvaluate: ApplicantModule.toEvaluate ? "1" : "0"
        },
        { withCredentials: true }
      );
      this.updateState(result);
      this.isLoadingCardView = false;
      return result;
    } catch (error) {
      this.isLoadingCardView = false;
      throw new Error();
    }
  }
  /**
   * Sets the TOTAL_APPLICANTS of vStore to the given @param total and then updates
   * the APP_BAR_TITLE to`Anzahl der Bewerber: ${this.$vStore.getters.totalApplicants}`
   * @param total The total number of applicants that match the filter parameters
   */
  setTitle(total: number) {
    ApplicantModule.SET_TOTAL_APPLICANTS(Number(total));
    AppModule.SET_APP_BAR_TITLE(`Anzahl der Bewerber: ${ApplicantModule.totalApplicants}`);
  }
  /**
   * @param result Contains the response of applicants with meta data to the given filter parameters
   *
   * Update the current page number, page size, sets IDS_ONLINE_FILTER, sets imageBase64 of all
   * applicants and then saves the data to store and sets this.isLoading to false.
   *
   * This method could move to vStore actions
   */
  updateState(result: AxiosResponse<any>) {
    if (result.data.list) {
      this.setTitle(result.data.meta.total);
      ApplicantModule.SET_HAS_RANKINGLIST(result.data.meta.has_rankinglist);
      if (result.data.headings) {
        AppModule.SET_APP_TOTAL_SIZE_TABLE(result.data.meta.total);
        this.noOfPages = this.calculatePagesCount(AppModule.appPageSizeTable, result.data.meta.total);
        if (!AppModule.appPageSizeTable) {
          AppModule.SET_APP_PAGESIZE_TABLE(result.data.list.length);
        }
        ApplicantModule.populateApplicantsTableViewStore({
          list: result.data.list,
          headings: result.data.headings
        });
      } else {
        result.data.list = mapApplicants(Object.entries(result.data.list));
        AppModule.SET_APP_PAGE(AppModule.appPage + 1);
        if (!AppModule.appPageSize) {
          AppModule.SET_APP_PAGESIZE(result.data.list.length);
        }
        ApplicantModule.populateStore(result.data.list);
      }
    } else {
      this.setTitle(0);
    }
  }
  calculatePagesCount = (pageSize: number, totalCount: number) => {
    // we suppose that if we have 0 items we want 1 empty page
    return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize);
  };
  filterOccupation(applicants: Applicant[]) {
    const positionId = ApplicantModule.idOfPosition;
    if (positionId === null) return applicants;
    return applicants.filter(
      (applicant: Applicant) => applicant.position_id === positionId
    );
  }
  filterArchiveEvaluate(applicants: Applicant[]) {
    return applicants.filter((applicant: Applicant) => {
      if (ApplicantModule.toEvaluate && !ApplicantModule.showArchive) {
        return applicant.rating.hasOpenEvaluation === Rated.NOT_STARTED;
      } else if (!ApplicantModule.toEvaluate && ApplicantModule.showArchive) {
        return applicant.rating.hasOpenEvaluation === Rated.FINISHED;
      }
      return ApplicantModule.toEvaluate && ApplicantModule.showArchive;
    });
  }
  async getSortable() {
    try {
      const response = await this.axios.get(`${APPLICANTS_SORT}`, {
        withCredentials: true
      });
      this.sortableList = response.data;
      let sortBy = [];
      let sortDesc = [];
      for (var index in this.sortableList) {
        if (this.sortableList[index].order !== null) {
          sortBy.push("val" + this.sortableList[index].key);
          sortDesc.push(this.sortableList[index].order === "DESC");
        }
      }
      this.options.sortBy = sortBy;
      this.options.sortDesc = sortDesc;
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  }
  prepareSortObject(sort: string[], desc: boolean[], page: number) {
    var resultSet = new Set<result>();
    for (var prop in sort) {
      var result: result = Object.create(null);
      (result["key"] = String(sort[prop].toString().replace("val", "")));
      (result["order"] = String(desc[prop] ? "DESC" : "ASC"));
      resultSet.add(result);
    }
    let cleanedResultSet = Array.from(resultSet);
    ApplicantModule.SET_SORTING(cleanedResultSet);
  }
  /*async buttonCallback() {
    var body = ApplicantModule.sorting;
    try {
      const { id } = this.$route.params;
      const response = await this.axios.post(
        `${APPLICANTS_SORT}`,
        ApplicantModule.sorting,
        { withCredentials: true }
      ).then(response => {
        if (response.status === 204) {
          this.getApplicantsForTable();
          //AppModule.SET_APP_UPDATE_NEEDED(!AppModule.appUpdateNeeded);
        }
      });
      // this.sorting =  Object.values(response.data);
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  }*/
  updatePagination(pagination: any) {
    AppModule.SET_APP_PAGE_TABLE(pagination);
    ApplicantModule.CLEAR_APPLICANTS_TABLE_VIEW();
    this.getApplicantsForTable();
  }
}
