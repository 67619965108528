










































import { APPLICANT_LIST, ACCOUNT_CHANGEPASSWORD } from "@/router/names";
import { Component, Mixins, Prop } from "vue-property-decorator";
import Widget from "@/mixins/Widget.mixin.vue";
import { AppModule } from "@/store";
import { LOGIN_URL } from "@/utils/requestUrl";

@Component({ mixins: [Widget] })
export default class AppNavigation extends Mixins(Widget) {
  AppModule = AppModule;

  @Prop(Boolean) value!: boolean;

  navigationItems = [
    {
      method: () => this.goTo(APPLICANT_LIST),
      name: "appNavigation.applicants",
      icon: "mdi-account-group-outline"
    },
    {
      method: () => this.goTo(ACCOUNT_CHANGEPASSWORD),
      name: "appNavigation.changePassword",
      icon: "mdi-account-cog-outline"
    },
    {
      method: () => this.logout(),
      name: "appNavigation.logout",
      icon: "mdi-logout"
    }
  ];

  tokenRefreshFailed = false;

  created() {
    this.axios.defaults.withCredentials = true;
  }

  goTo(name: string) {
    this.$router.push({ name });
  }

  async logout() {
    try {
      await this.axios.delete(LOGIN_URL, { withCredentials: true }).then(() => {
        AppModule.logout();
        this.$store.unregisterModule("applicant");
        this.$store.unregisterModule("app");
        window.sessionStorage.clear();
        window.location.reload();
      });
    } catch (e) {
    }
  }
}

