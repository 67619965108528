





















import { Component, Vue } from 'vue-property-decorator';
import AppHeader from './components/core/AppHeader.vue';
import AppNavigation from './components/core/AppNavigation.vue';
@Component({
  components: {
    AppHeader,
    AppNavigation,
  },
})
export default class ApplicantList extends Vue {
    drawer= false;
    isStateReady= false;
    created() {
      this.isStateReady = true;
    }
}
