


































































































































































































































import PDFViewerMixin from '@/mixins/PDFViewerMixin.mixin.vue';
import {
  Container, File, LabelRequired, LabelValue, MetaContainer,
} from '@/types';
import { ASSESSMENT_EXTENSION, BASE_URL, CUTE } from '@/utils/requestUrl';
import { Component, Mixins } from 'vue-property-decorator';
import PdfVue from '@/components/base/PdfVue.vue';
//import PdfViewer from '@/components/base/PdfViewer.vue';
import ContainerMapper from '../../../../utils/ArrayContainerConverter';

@Component({
  components: { PdfVue },
  mixins: [PDFViewerMixin],
})
export default class ApplicantTestCute
  extends Mixins(PDFViewerMixin) {
    labelValuesArray:LabelValue<string>[] = [] ;
    panel = [0,1];
    file: File | undefined;

    async created() {
      this.axios.defaults.withCredentials = true;
      const { id } = this.$route.params;
      const res = await this.axios.get<Container<MetaContainer>>(`${BASE_URL + ASSESSMENT_EXTENSION}/${id + CUTE}`, {withCredentials: true});
      const { meta } = res.data[0];
      this.labelValuesArray = meta ? ContainerMapper
        .getArrayOfContainer<LabelValue<string>>(meta) : [];
    }

    // eslint-disable-next-line class-methods-use-this
    getColor(LabelValues: Container<LabelValue<string>>) {
      return ContainerMapper.getArrayOfContainer<LabelValue<string>>(LabelValues)
        .find((value) => value.style)?.style?.background;
    }

         pdfView(isDownload:boolean) {
      this.options.attachments = Object.fromEntries(Object.entries(this.options.attachments)
        .map((entry) => [entry[0],
        { label: entry[1].label, required: false }as LabelRequired]))as any;

      this.options.options = Object.fromEntries(Object.entries(this.options.options)
        .map((entry) => [entry[0],
        { label: entry[1].label, required: false }as LabelRequired]))as any;
      this.options.attachments.checkix.required = true;
      this.postOptionsPdf(isDownload);
    }
}
