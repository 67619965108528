import Vue from "vue";
import Moment from "vue-moment";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import setGuards from "./router/guards";
import App from "./App.vue";
import http from "./plugins/http";

http();
setGuards(router);
Vue.use(Moment);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");