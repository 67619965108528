
import { Vue, Component } from "vue-property-decorator";

@Component({ components: {} })
export default class Widget extends Vue {
  get widgetElevation() {
    return this.$vuetify.breakpoint.name === "xs" ? 0 : 6;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.name === "xs";
  }
}

