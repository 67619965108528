































































































import PDFViewerMixin from '@/mixins/PDFViewerMixin.mixin.vue';
import {

  Container,
  File, LabelRequired, MetaContainer, PdfOption, TestContainer,
} from '@/types';
import {
  APPLICANT_URL, ASSESSMENT_EXTENSION, BASE_URL, HOGREFE, PDF_EXTENSION,
} from '@/utils/requestUrl';
import { Component, Mixins } from 'vue-property-decorator';
import PdfVue from '@/components/base/PdfVue.vue';


@Component({
  components: { PdfVue },
  mixins: [PDFViewerMixin],
})
export default class ApplicantTestHogrefe
  extends Mixins(PDFViewerMixin) {
    file: File|null = null;

    open={ open: false };

    testContainer: Container<MetaContainer> = {};

    async created() {
      this.axios.defaults.withCredentials = true;
      const { id } = this.$route.params;
      const res = await this.axios.get<TestContainer>(`${BASE_URL + ASSESSMENT_EXTENSION}/${id + HOGREFE}`, {withCredentials: true});
      this.testContainer = res.data.tests;
      this.file = res.data.file as File;

      this.generateFileUrl = `${APPLICANT_URL}/${id}${PDF_EXTENSION}`;
      this.axios.get<PdfOption>(
        this.generateFileUrl, /*{withCredentials: true}*/
      )
        .then((res) => {
          this.options = res.data;
        });
    }

    pdfHogrefe(isDownload:boolean) {
      this.options.attachments = Object.fromEntries(Object.entries(this.options.attachments)
        .map((entry) => [entry[0],
        { label: entry[1].label, required: false }as LabelRequired]))as any;

      this.options.options = Object.fromEntries(Object.entries(this.options.options)
        .map((entry) => [entry[0],
        { label: entry[1].label, required: false }as LabelRequired]))as any;
      this.options.attachments.hogrefe.required = true;
      this.postOptionsPdf(isDownload);
    }
}
