




























import ApplicantDetailAttachments from "@/components/applicant/detail/ApplicantDetailAttachments.vue";
import ApplicantDetailPersonal from "@/components/applicant/detail/ApplicantDetailPersonal.vue";
import ApplicantTest from "@/mixins/ApplicantTests.mixin.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    ApplicantDetailAttachments,
    ApplicantDetailPersonal,
    ApplicantTest
  }
})
export default class ApplicantGeneral extends Vue {

}
