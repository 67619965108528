import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
import DefaultRouterView from "@/components/core/DefaultRouterView.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import Account from "./account";
import Applicant from "./applicant";
import { LOGIN } from "./names";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: LOGIN,
    meta: {
      requiredPrivileges: { publicRoute: true }
    },
    component: Login
  },
  {
    path: "/set-password",
    meta: {
      requiredPrivileges: { publicRoute: true }
    },
    component: ForgotPassword
  },

  {
    path: "/account",
    component: DefaultRouterView,
    meta: {
      navigation: {
        icon: "mdi-account",
        label: "Account"
      },
      requiredPrivileges: {}
    },
    children: [...Account.routes]
  },
  {
    path: "/applicants",
    component: DefaultRouterView,
    meta: {
      navigation: {
        icon: "mdi-calendar-multiple",
        label: "Applicant"
      },
      requiredPrivileges: {}
    },
    children: [...Applicant.routes]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
