/* eslint-disable camelcase */
const isEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};
// Funktion gibt die Anzahl des Matches gegen eine Regex die der übergebene String enthält zurück
const numberOfMatches = (toValidate: string, regex: RegExp) => (([...(toValidate || "").matchAll(regex)] || []).length);

// eslint-disable-next-line import/prefer-default-export
export { isEmail, numberOfMatches };
