















































































// eslint-disable-next-line import/no-cycle
import { ApplicantModule } from "@/store";
import { Applicant } from "@/types";
import { APPLICANT_URL, OTHER_EXTENSION } from "@/utils/requestUrl";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class ApplicantOther extends Vue {
  ApplicantModule = ApplicantModule;

  created() {
    this.getOther();
  }

  async getOther() {
    try {
      const { id } = this.$route.params;
      const response = await this.axios.get(`${APPLICANT_URL}/${id}${OTHER_EXTENSION}`, { withCredentials: true });
      const result: Applicant = {
        ...ApplicantModule.applicant,
        otherData: response.data
      } as Applicant;
      ApplicantModule.SET_APPLICANT(result as Applicant);
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  }
}
