import { ACCOUNT_SETTINGS, ACCOUNT_OVERVIEW, ACCOUNT_CHANGEPASSWORD } from "./names";
import SettingsPage from "../views/account/SettingsPage.vue";
import ChangePasswordPage from "../views/account/ChangePasswordPage.vue";

export default {
  routes: [
    {
      path: "",
      redirect: { name: ACCOUNT_OVERVIEW },
      meta: { navigation: { hide: true } }
    },
    {
      path: "settings",
      name: ACCOUNT_SETTINGS,
      component: SettingsPage,
      meta: {
        navigation: {
          icon: "mdi-account-cog",
          label: "Settings"
        }
      }
    },
    {
      path: "changePassword",
      name: ACCOUNT_CHANGEPASSWORD,
      component: ChangePasswordPage,
      meta: {
        navigation: {
          icon: "mdi-account-cog",
          label: "ChangePassword"
        }
      }
    }
  ]
};
