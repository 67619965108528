
import Snackbar from "@/mixins/Snackbar.mixin.vue";
import { PasswordRules } from "@/types";
import { numberOfMatches } from "@/utils/validator";
import { AxiosError, AxiosResponse } from "axios";
import VueI18n from "vue-i18n";
import { Component, Mixins, Ref } from "vue-property-decorator";

@Component({ mixins: [Snackbar] })
export default class PasswordRule extends Mixins(Snackbar) {
  dialog = false;

  valid = false;

  loading = false;

  response: VueI18n.TranslateResult = "";

  currentPassword = "";

  password = "";

  confirmPassword = "";

  currentPasswordRules = [(v: string) => !!v || "Password ist erforderlich"];

  passwordRules = [(v: string) => !!v || "Password ist erforderlich"];

  confirmPasswordRules = [(v: string) => !!v || "Password ist erforderlich"];

  globalPasswordRules?: PasswordRules;

  passwordValid = true;

  static urlGet: string;

  static urlPost: string;

  static getObjectPost: () => Object;

  @Ref("form") form!: HTMLFormElement;

  get passwordRule() {
    const pw = this.password;
    if (this.globalPasswordRules) {
      if (numberOfMatches(pw, /\d/g) < this.globalPasswordRules.password_contains_numbers) {
        return this.$tc("changePassword.error.number", this.globalPasswordRules.password_contains_numbers);
      }
      if (numberOfMatches(pw, /[a-z]/g) < this.globalPasswordRules.password_contains_lower_case) {
        return this.$tc("changePassword.error.lowerCase", this.globalPasswordRules.password_contains_lower_case);
      }
      if (this.globalPasswordRules.password_contains_upper_case && numberOfMatches(pw, /[A-Z]/g) < this.globalPasswordRules.password_contains_upper_case) {
        return this.$tc("changePassword.error.upperCase", this.globalPasswordRules.password_contains_upper_case);
      }
      if (numberOfMatches(pw, /\W/g) < this.globalPasswordRules.password_contains_special_chars) {
        return this.$tc("changePassword.error.specialChar", this.globalPasswordRules.password_contains_special_chars);
      }
      if (this.password.length < this.globalPasswordRules.min_password_length) {
        return this.$t("changePassword.error.minLength");
      }
      if (this.password.length > this.globalPasswordRules.max_password_length) {
        return this.$t("changePassword.error.maxLength");
      }
    }

    // return true to remove error in password field
    return true;
  }

  get passwordConfirmationRule() {
    if (this.password !== this.confirmPassword) {
      return this.$t("changePassword.error.equal");
    }
    return true;
  }

  validate() {
    this.form.validate();
  }

  async getPasswordRules() {
    try {
      this.loading = true;
      const result: AxiosResponse<PasswordRules> = await this.axios
        .get(PasswordRule.urlGet);
      this.globalPasswordRules = result.data;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  }

  async sendPassword() {
    this.loading = true;
    await this.axios.post(PasswordRule.urlPost, PasswordRule.getObjectPost(), { withCredentials: true }).then(() => {
      this.snackbarHandler(200, this.$t("appNavigation.passwordChangedSuccessfully"));
      this.response = this.$t("appNavigation.passwordChangedSuccessfully");
      this.dialog = true;
      this.loading = false;
    })
      .catch((e: AxiosError) => {
        this.loginStatus = e.response?.status;
        this.snackbar = true;
        // password is wrong
        if (this.loginStatus && this.loginStatus < 500) {
          this.snackbarHandler(401, `${Object.values(e.response?.data).toString()}`);
          const errorObjectArray = Object.values(e.response?.data);
          const listOfErrors = errorObjectArray.reduce(
            (prev: string[], current: any): string[] => (
              prev.concat(Object.values(current))), []
          );
          this.response = listOfErrors.reduce((prev, current) => {
            if (prev) return prev.concat("\n").concat(current);
            return current;
          });
        } else {
          this.snackbarHandler(500, this.$t("appNavigation.unknownError"));
          this.response = this.$t("appNavigation.unknownError");
        }
        this.dialog = true;
        this.loading = false;
      });
  }

  /**
   * @method: changepassword
   * @description: Check change password form and
   * sends data together with the jwt from the store to the server
   * TODO: write function
   */
}
