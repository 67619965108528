















import { Component, Mixins } from "vue-property-decorator";
import ApplicantTestAlpha from "@/components/applicant/detail/Tests/ApplicantTestAlpha.vue";
import ApplicantTestCute from "@/components/applicant/detail/Tests/ApplicantTestCute.vue";
import ApplicantTestUform from "@/components/applicant/detail/Tests/ApplicantTestUform.vue";
import ApplicantTestHogrefe from "@/components/applicant/detail/Tests/ApplicantTestHogrefe.vue";
import WidgetMixin from "./Widget.mixin.vue";
import PDFViewerMixin from "./PDFViewerMixin.mixin.vue";

@Component({
  components: {
    ApplicantTestAlpha,
    ApplicantTestHogrefe,
    ApplicantTestUform,
    ApplicantTestCute
  },
  mixins: [WidgetMixin, PDFViewerMixin]
})
export default class ApplicantTests extends Mixins(WidgetMixin, PDFViewerMixin) {

}
