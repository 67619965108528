
































































































































import PasswordRule from "@/mixins/PasswordRule.mixin.vue";
import { APPLICANT_LIST } from "@/router/names";
import { PASSWORD_URL } from "@/utils/requestUrl";
import { AppModule } from "@/store";
import { Component, Mixins } from "vue-property-decorator";

@Component({
  mixins: [PasswordRule]
})
export default class changePassword extends Mixins(PasswordRule) {
  created() {
    PasswordRule.urlPost = PASSWORD_URL;
    PasswordRule.urlGet = PASSWORD_URL;
    PasswordRule.getObjectPost = () => ({
      oldPassword: this.currentPassword,
      newPassword: this.password
    });
    this.getPasswordRules();
    AppModule.SET_APP_BAR_TITLE("");
  }

  closeSite() {
    this.$router.push({ name: APPLICANT_LIST });
  }

  closeDialog() {
    if (this.loginStatus && this.loginStatus < 300) {
      this.closeSite();
    } else this.dialog = false;
  }

  /**
   * @method: changepassword
   * @description: Check change password form and
   * sends data together with the jwt from the store to the server
   * TODO: write function in {@link PasswordRule}
   */
}
