

































































































// eslint-disable-next-line import/no-cycle
import { ApplicantModule } from "@/store";
import { Applicant, LabelValue } from "@/types";
import { APPLICANT_URL, CAREER } from "@/utils/requestUrl";
import { Vue, Component, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

@Component({ components: {} })
export default class ApplicantDevelopment extends Vue {
  @Ref("tableCard") tableCard!: HTMLElement[];

  ApplicantModule = ApplicantModule;

  headers: DataTableHeader[] = [
    { text: "", value: "label", width: 140 },
    { text: "", value: "value" }
  ];

  created() {
    this.getDevelopment();
  }

  // eslint-disable-next-line class-methods-use-this
  getItems(item: LabelValue<LabelValue<string>>) {
    return Object.values(item.value)
      .map((obj: LabelValue<string | LabelValue<string>>) => {
        if (typeof obj.value === "object") {
          obj.value = Object.values(obj.value)
            .reduce((prev, current) => prev.concat(
              `${current.label}: ${current.value.toString()}\n`
            ),
            ""
          );
        }
        return {
          label: obj.label, value: obj.value
        };
      }
    );
  }

  async getDevelopment() {
    try {
      const { id } = this.$route.params;
      const response = await this.axios.get(`${APPLICANT_URL}/${id}${CAREER}`, { withCredentials: true });
      const result: Applicant = {
        ...ApplicantModule.applicant,
        development: response.data
      } as Applicant;
      ApplicantModule.SET_APPLICANT(result);
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  }
}
