import Vue from "vue";
import Vuex from "vuex";
import { getModule } from "vuex-module-decorators";
import AppStore from "./appStore";
import ApplicantStore from "./applicantStore";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export interface IRootState {
  applicant: typeof ApplicantStore;
  app: typeof AppStore;
}

// Declare empty store first, dynamically register all modules later.
const store = new Vuex.Store<IRootState>({
  modules: {
    applicant: ApplicantStore,
    app: AppStore
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
});

//TODO Naming Applicant Module to Applicant store
export const ApplicantModule = getModule(ApplicantStore, store);
export const AppModule = getModule(AppStore, store);

export default store;
