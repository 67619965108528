





























































































































































import ChangePassword from "@/components/account/ChangePassword.vue";
import { Component, Ref, Vue } from "vue-property-decorator";

@Component({ components: { ChangePassword } })
export default class Settings extends Vue {
  dialog = false;

  valid = true;

  firstName = "";

  lastName = "";

  @Ref("form") form!: HTMLFormElement;

  nameRules = [
    (v: string) => !!v || "Name is required",
    (v: string) => (v && v.length <= 255) || "Name must be less than 255 characters"
  ];

  email = "";

  emailRules = [
    (v: string) => !!v || "E-Mail is required",
    (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    (v: string) => (v && v.length <= 255) || "Mail must be less than 255 characters"
  ];

  select = "Privat";

  gender = ["Maennlich", "Weiblich", "Privat"];

  genderRules = [(v: string) => !!v || "Gender is required"];

  checkbox = false;

  password = "bananenbrot";

  validate() {
    this.form.validate();
  }
}
