







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Logo extends Vue {
  @Prop({ default: 100 }) maxWidth!: number;
}
