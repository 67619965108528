









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DefaultRouterView extends Vue {
  @Prop({ default: "router-anim" }) routeName!: string;

  @Prop({ default: () => {} }) config!: any;
}
