import VueRouter, { Route } from "vue-router";
import store, { AppModule } from "../store";
import { LOGIN, APPLICANT_LIST } from "./names";
import { Vue } from "vue-class-component/dist/vue-class-component";

function routeGuard(router: VueRouter) {
  router.beforeEach(async (to: Route, from: Route, next: CallableFunction) => {
    try {
      // instantly catch and redirect public routes
      if (to.meta && to.meta.requiredPrivileges && to.meta.requiredPrivileges.publicRoute) {
        if (AppModule.loggedIn) {
          // redirect to the default page if the user is logged in
          return next({ name: APPLICANT_LIST });
        } else {
          // otherwise, redirect to the desired route
          return next();
        }
      }

      // prepare redirect of internal routes
      if (AppModule.loggedIn) {
        // redirect to the desired route if the user is logged in
        next();
      } else {
        // otherwise, redirect to the login page
        next({ name: LOGIN });
      }
      // redirect and assign previous route
      return next((vm: Vue) => {
        vm.prevRoute = from;
      });
    } catch (e) {
      await store.dispatch("logout");
      throw new Error("Authentication failed!");
    }
  });
}

export default function setGuards(router: VueRouter) {
  routeGuard(router);
}
