



















































































































































import ApplicantDetailNotice from "@/components/applicant/detail/ApplicantDetailNotice.vue";
import WidgetMixin from "@/mixins/Widget.mixin.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import VueI18n from "vue-i18n";
import { APPLICANT_URL, EVALUATION_EXTENSION } from "@/utils/requestUrl";
import {
  AnswerRatingSchema,
  AnswerRatingSchemaServer,
  Container,
  getInitAnswer,
  Questions,
  Rated,
  Rating,
  RatingSchema
} from "@/types";
import { ApplicantModule } from "@/store";
import ApplicantRating from "@/components/applicant/detail/ApplicantRating.vue";
import ApplicantRatingAnswers from "@/components/applicant/detail/ApplicantRatingAnswers.vue";
import RatingSaveDialog from "@/components/applicant/detail/RatingSaveDialog.vue";
import Converter from "@/utils/ArrayContainerConverter";

@Component({
  components: {
    RatingSaveDialog,
    ApplicantRating,
    ApplicantDetailNotice,
    ApplicantRatingAnswers
  },
  mixins: [WidgetMixin]
})
export default class ApplicantRatingSheet extends Vue {
  ApplicantModule = ApplicantModule;

  Questions = Questions;

  snackbar = false;

  snackbarMessage: VueI18n.TranslateResult = "";

  snackbarColor = "success";

  snackbarIcon = "mdi-send";

  isLoading = false;

  /** depending on the @param this.requiredRule the send btn gets disabled */
  valid = false;

  /** [((v:any) => !!v || v === false)] */
  requiredRule = [(v: any) => !!v || v === false];

  /** @sheet opens/closes the bottom-sheet for the rating form or rating answer list */
  sheet = false;

  /** @onPageChange saves the rating to the state-manager if bottom-sheet got closed */
  @Watch("sheet")
  onPageChange(currentSheet: boolean) {
    if (!currentSheet) {
      ApplicantModule.SET_APPLICANT_RATING({
        answers: this.answers,
        points: this.answers.find(
          (a) => Number(a.type) === Questions.QUESTION_RANKING
        )?.value
      } as Rating);
    }
  }

  openSaveDialog = false;

  /**
   * @questionsAsArray contains the questions as array and is needed
   * for displaying the rating form
   */
  questionsAsArray: RatingSchema[] = [];

  /** @answers can be edited and sent to server if valid */
  answers: Array<AnswerRatingSchema> = [];

  /** Contains the response of rating answers if rating is finished */
  ratedAnswers: AnswerRatingSchemaServer[] = [];

  /**
   * depending on the current state of @hasOpenEvaluation, gets the questions for rating an
   * applicant or retrieves the answers from the server to show the finished rating
   */
  async created() {
    if (ApplicantModule.applicantRating?.hasOpenEvaluation === Rated.NOT_FINISHED) {
      this.answers = [...ApplicantModule.applicantRating.answers];

      this.questionsAsArray = Converter.getArrayOfContainer(
        ApplicantModule.applicantRating?.questions
      );
    } else if (ApplicantModule.applicantRating?.hasOpenEvaluation === Rated.NOT_STARTED) {
      await this.getQuestions();
      this.questionsAsArray = Converter.getArrayOfContainer(
        ApplicantModule.applicantRating?.questions
      );
      this.answers = ApplicantModule.applicantRating?.answers
        ? [...ApplicantModule.applicantRating.answers]
        : [];
      if (this.answers.length === 0) {
        this.initAnswers();
      }
    } else if (ApplicantModule.applicantRating?.hasOpenEvaluation === Rated.FINISHED) {
      await this.getRatedAnswers();
    }
  }

  /**
   * Determines whether to show the send rating button. Otherwise, a deactivated
   * button containing a message is displayed instead.
   * The send button should be displayed if the rating has been selected and
   * the form is valid.
   */
  get showSendButton(): boolean {
    if (this.valid && !this.ratingFinished) {
      let answer = this.answers.find((a) => Number(a.type) === Questions.QUESTION_RANKING);
      if (answer) {
        return answer.value !== 0;
      }
    }
    return false;
  }

  /** creates the answer objects for editing */
  initAnswers() {
    this.questionsAsArray.forEach((schema) => {
      this.answers.push(getInitAnswer(schema.id, schema.type));
    });
  }

  /**
   * retrieves the rating answers from the server and sets
   * this.ratedAnswers to the mapped answers
   */
  async getRatedAnswers() {
    const { id } = this.$route.params;
    this.ratedAnswers = await this.axios
      .get<{
        [index: number]: Container<{ answer: Container<AnswerRatingSchema> }>;
      }>(`${APPLICANT_URL}/${id}${EVALUATION_EXTENSION}s`)
      .then(
        (res) => Converter.getArrayOfContainer(res.data)
          .map((answersObject: any) => Converter.getArrayOfContainer(answersObject.answers))
          .pop() as AnswerRatingSchemaServer[]
      );
  }

  /**
   * @if hasOpenEvaluation === Rated.NOT_STARTED retrieves the Rating answers from the server
   * and updates the questions to the store
   * @else opens the rating sheet for reviewing the answers
   */
  async getQuestions() {
    const { id } = this.$route.params;
    if (ApplicantModule.applicantRating?.hasOpenEvaluation === Rated.NOT_STARTED) {
      await this.axios
        .get<Rating>(`${APPLICANT_URL}/${id}${EVALUATION_EXTENSION}`, { withCredentials: true })
        .then((res) => {
          ApplicantModule.SET_APPLICANT_RATING(res.data);
          ApplicantModule.SET_APPLICANT_IS_RATED(res.data.hasOpenEvaluation);
        });
    } else {
      this.sheet = true;
    }
  }

  get ratingFinished() {
    return ApplicantModule.applicantRating?.hasOpenEvaluation === Rated.FINISHED;
  }

  /** params actionResult (Boolean) */
  handleDialogResult(actionResult: boolean) {
    if (actionResult) {
      this.sendData();
    }
  }

  snackbarHandler(
    color: string,
    message: VueI18n.TranslateResult,
    icon = this.snackbarIcon
  ) {
    this.snackbar = true;
    this.snackbarIcon = icon;
    this.snackbarColor = color;
    this.snackbarMessage = message;
  }

  /**
   * @try tries to post the rating answers to the server and throws an error
   * if the response object res.data.success === 0
   * @success closes rating sheet, sets hasOpenEvaluation to Rated.FINISHED,
   * sets success to snackbar
   * and retrieves the created answer object for reviewing
   * @catch sets error to snackbar
   */
  async sendData() {
    try {
      const { id } = this.$route.params;
      this.isLoading = true;
      await this.axios
        .post(`${APPLICANT_URL}/${id}${EVALUATION_EXTENSION}`, {
          answers: Converter.getContainerOfArray<AnswerRatingSchema>(
            this.mapAnswers()
          )
        }, { withCredentials: true })
        .then((res) => {
          console.log({
            answers: Converter.getContainerOfArray<AnswerRatingSchema>(
              this.mapAnswers()
            )
          });
          if (res.data.success === 0) {
            throw new Error(
              res.data.errors.toString() + res.data.generalError.toString()
            );
          }
        });
      this.snackbarHandler(
        "success",
        this.$t("applicantDetail.rating.successfullySaved")
      );
      this.isLoading = false;
      this.sheet = false;
      ApplicantModule.SET_APPLICANT_IS_RATED(Rated.FINISHED);
      await this.getRatedAnswers();
    } catch (error) {
      this.snackbarHandler(
        "error",
        this.$t("applicantDetail.rating.errorOnSaving"),
        "mdi-alert-octagon-outline"
      );
      this.isLoading = false;
    }
  }

  /** opens the dialog for cancel or post message */
  onSubmit() {
    this.openSaveDialog = true;
  }

  /** @if !this.answers gets the answer object for demand document */
  getDemandDocAnswer() {
    if (!this.answers) return this.answers;
    return this.answers.find(
      (answers) => Number(answers.type) === Questions.QUESTION_REQUEST_DOCUMENTS
    );
  }

  childFinishedLoading() {
    this.isLoading = false;
  }

  /**
   * maps all answers the user made to a new array of answers so that they fit
   * to the required types of the server
   */
  mapAnswers() {
    return this.answers.map((answer) => {
      const newAnswer: AnswerRatingSchema = { ...answer };
      if (Number(newAnswer.type) === Questions.QUESTION_RANKING) {
        newAnswer.value = Array.from(Array(newAnswer.value).keys())
          .map(() => newAnswer.value.toString())
          .reduce((prev = "", current) => prev + current);
      }
      if (
        Number(newAnswer.type) === Questions.QUESTION_INTERVIEW
        || Number(newAnswer.type) === Questions.QUESTION_ARRANGE_INTERVIEW_DATE
        || Number(newAnswer.type) === Questions.QUESTION_INVITE_TO_TEST
        || Number(newAnswer.type) === Questions.QUESTION_EMPLOY
        || Number(newAnswer.type) === Questions.QUESTION_ADD_TO_TALENT_POOL
        || Number(newAnswer.type) === Questions.QUESTION_CANCEL
        || Number(newAnswer.type) === Questions.QUESTION_TOOK_NOTE
        || Number(newAnswer.type) === Questions.QUESTION_EMPLOYMENT_APPROVAL
      ) {
        newAnswer.value = Number(newAnswer.value);
      } else if (Number(newAnswer.type) === Questions.QUESTION_CUSTOM_TEXT) {
        newAnswer.value = newAnswer.text;
      }
      return { ...newAnswer };
    });
  }
}
