// NOT FOUND
export const NOT_FOUND = "not_found";

// LOGIN
export const LOGIN = "login";

// APPOINTMENT MODULE
export const APPLICANT = "applicants";
export const APPLICANT_LIST = "applicants_list";
export const APPLICANT_DETAIL = "applicant_detail";
export const APPLICANT_DETAIL_GENERAL = "applicant_detail_general";
export const APPLICANT_DETAIL_COMMENTS = "applicant_detail_comments";
export const APPLICANT_DETAIL_DEVELOPMENT = "applicant_detail_development";
export const APPLICANT_DETAIL_QUALIFICATION = "applicant_detail_qualification";
export const APPLICANT_DETAIL_TESTS = "applicant_detail_tests";
export const APPLICANT_DETAIL_OTHER = "applicant_detail_oither";

export const APPLICANT_RATING = "applicant_rating";

// ACCOUNT MODULE
export const ACCOUNT = "account";
export const ACCOUNT_OVERVIEW = "account_overview";
export const ACCOUNT_SETTINGS = "account_settings";
export const ACCOUNT_CHANGEPASSWORD = "account_changepassword";

