import ApplicantList from "@/views/applicant/ApplicantList.vue";
import ApplicantDetail from "@/views/applicant/ApplicantDetail.vue";
import ApplicantGeneral from "@/views/applicant/ApplicantGeneral.vue";
import ApplicantComments from "@/views/applicant/ApplicantComments.vue";
import ApplicantDevelopment from "@/views/applicant/ApplicantDevelopment.vue";
import ApplicantOther from "@/views/applicant/ApplicantOther.vue";
import ApplicantQualification from "@/views/applicant/ApplicantQualification.vue";
import ApplicantTests from "@/views/applicant/ApplicantTests.vue";
import {
  APPLICANT_LIST,
  APPLICANT_DETAIL_GENERAL,
  APPLICANT_DETAIL_COMMENTS,
  APPLICANT_DETAIL_OTHER,
  APPLICANT_DETAIL,
  APPLICANT_DETAIL_QUALIFICATION,
  APPLICANT_DETAIL_DEVELOPMENT,
  APPLICANT_DETAIL_TESTS
} from "@/router/names";

export default {
  routes: [
    {
      path: "",
      name: APPLICANT_LIST,
      component: ApplicantList,
      meta: {}
    },
    {
      path: ":id",
      component: ApplicantDetail,
      name: APPLICANT_DETAIL,
      redirect: { name: APPLICANT_DETAIL_GENERAL },
      children: [
        {
          path: "",
          redirect: { name: APPLICANT_DETAIL_GENERAL }
        },
        {
          path: "general",
          name: APPLICANT_DETAIL_GENERAL,
          component: ApplicantGeneral,
          meta: {}
        },
        {
          path: "comments",
          name: APPLICANT_DETAIL_COMMENTS,
          component: ApplicantComments,
          meta: {}
        },
        {
          path: "other",
          name: APPLICANT_DETAIL_OTHER,
          component: ApplicantOther,
          meta: {}
        },
        {
          path: "development",
          name: APPLICANT_DETAIL_DEVELOPMENT,
          component: ApplicantDevelopment,
          meta: {}
        },
        {
          path: "qualification",
          name: APPLICANT_DETAIL_QUALIFICATION,
          component: ApplicantQualification,
          meta: {}
        },
        {
          path: "tests",
          name: APPLICANT_DETAIL_TESTS,
          component: ApplicantTests,
          meta: {}
        }
      ]
    }

  ]
};
