import { Applicant, Container, INIT_VALUE, LabelValue, Position, Rated, Rating, result } from "@/types";
import _ from "lodash";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

@Module({
  name: "applicant",
  namespaced: true,
  stateFactory: true
})
export default class ApplicantStore extends VuexModule {
  private _id = "0";

  private _openPositions: Container<LabelValue<Position>> = {};

  private _showArchive = false;

  private _toEvaluate = true;

  private _search = "";

  private _positions: Array<string> = [""];

  private _applicants: Array<Applicant> = [];

  private _ids: Array<String> = [];

  private _totalApplicants = 0;

  private _hasRankinglist = false;

  private _sorting: result[] = [];

  private _isSortable = false;

  private _applicantsTableView: any = [];
  private _tableViewHeaders: any = [];

  get isSortable() {
    return !_.isEmpty(this._sorting);
  }

  get totalApplicants() {
    return this._totalApplicants;
  }

  get hasRankinglist() {
    return this._hasRankinglist;
  }

  get applicantId() {
    return this._id;
  }

  get openPositions() {
    return this._openPositions;
  }

  get showArchive() {
    return this._showArchive;
  }

  get toEvaluate() {
    return this._toEvaluate;
  }

  get search() {
    return this._search;
  }

  get positions() {
    return this._positions;
  }

  get applicantsTableView() {
    return this._applicantsTableView;
  }

  get tableViewHeaders() {
    return this._tableViewHeaders;
  }

  get applicants() {
    return this._applicants;
  }

  get applicant(): Applicant | undefined {
    return this._applicants.find((appl) => appl.id === this._id);
  }

  get applicantRating(): Rating | undefined {
    return this.applicant?.rating;
  }

  get applicantsFilter() {
    const result: Applicant[] = [];
    this._ids.forEach(
      (id) => result.push(<Applicant>this._applicants.find((appl) => appl.id === id))
    );
    return result;
  }

  get idOfPosition() {
    const entriesOfPositions: [string, Position][] = Object.entries(this._openPositions);
    const res = entriesOfPositions.find((entry) => (entry[1].position === this._positions[0]));
    if (res) return Number(res[0]);
    return null;
  }

  get sorting() {
    return this._sorting;
  }

  /**
   * @param applicants
   * this action pushes only new applicants and to the store
   */
  @Action({ rawError: true })
  async populateStore(applicants: Applicant[]) {
    try {
      this.SET_APPLICANTS(applicants);
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  }

  @Action({ rawError: true })
  async populateApplicantsTableViewStore(applicants: any) {
    try {
      this.SET_APPLICANTS_TABLE_VIEW(applicants.list);
      this.SET_TABLE_VIEW_HEADERS(applicants.headings);
    } catch (error) {
      if (process.env.NODE_ENV === "development") console.log(error);
    }
  }

  @Mutation
  SET_IS_SORTABLE(isSortable: boolean) {
    this._isSortable = isSortable;
  }

  @Mutation
  SET_APPLICANT_IS_RATED(hasOpenEvaluation: number) {
    const index = this._applicants.findIndex((element: Applicant) => element.id === this._id);
    this._applicants[index].rating.hasOpenEvaluation = hasOpenEvaluation;
  }

  @Mutation
  SET_APPLICANT(applicant: Applicant) {
    const index = this._applicants.findIndex((element: Applicant) => element.id === this._id);

    if (index > -1) {
      this._applicants[index] = { ...this._applicants[index], ...applicant };
      this._applicants = [...this._applicants];
    } else {
      this._applicants = [...this._applicants, applicant];
    }
    this._id = applicant.id;
  }

  @Mutation
  SET_APPLICANT_RATING(rating: Rating) {
    const index = this._applicants.findIndex((element: Applicant) => element.id === this._id);
    let isEdited = false;
    if (rating.answers) {
      rating.answers.forEach((a) => {
        if (a.type !== "100" && a.value !== INIT_VALUE) {
          isEdited = true;
        }
      });
    }
    const newHasOpenEvaluation = (isEdited && this._applicants[index].rating.hasOpenEvaluation !== 0)
      ? Rated.NOT_FINISHED
      : this._applicants[index].rating.hasOpenEvaluation;

    this._applicants[index].rating = {
      ...this._applicants[index].rating,
      ...rating,
      hasOpenEvaluation: newHasOpenEvaluation
    };
  }

  @Mutation
  SET_APPLICANT_EDITED(edited: boolean) {
    const index = this._applicants.findIndex((element: Applicant) => element.id === this._id);
    this._applicants[index]._edited_rating = edited;
  }

  @Mutation
  SET_APPLICANT_ID(id: string) {
    this._id = String(id);
  }

  @Mutation
  SET_TOTAL_APPLICANTS(total: number) {
    this._totalApplicants = total;
  }


  @Mutation
  SET_HAS_RANKINGLIST(has_rankinglist: boolean) {
    this._hasRankinglist = has_rankinglist;
  }

  @Mutation
  SET_OPEN_POSITIONS(openPositions: Container<LabelValue<Position>>) {
    this._openPositions = openPositions;
  }

  @Mutation
  SET_SHOW_ARCHIVE(showArchive: boolean) {
    this._showArchive = showArchive;
  }

  @Mutation
  SET_TO_EVALUATE(toEvaluate: boolean) {
    this._toEvaluate = toEvaluate;
  }

  @Mutation
  SET_SEARCH(search: string) {
    this._search = search;
  }

  @Mutation
  SET_POSITIONS(positions: string[]) {
    this._positions = positions;
  }

  @Mutation
  SET_APPLICANTS(applicant: Applicant[]) {
    this._ids = [];
    applicant.forEach((appl: Applicant) => {
      this._ids.push(appl.id);
    });
    this._applicants.push(...applicant);
  }

  @Mutation
  SET_APPLICANTS_TABLE_VIEW(applicants: Applicant[]) {
    this._applicantsTableView = [...this._applicantsTableView, ...applicants];
  }

  @Mutation
  CLEAR_APPLICANTS_TABLE_VIEW() {
    this._applicantsTableView = [];
  }

  @Mutation
  SET_TABLE_VIEW_HEADERS(headers: any[]) {
    this._tableViewHeaders = headers;
  }


  @Mutation
  CLEAR_APPLICANTS() {
    this._applicants = [];
    this._ids = [];
  }

  @Mutation
  SET_IMAGE_BASE64(base64: string) {
    const appl = this._applicants.find((appl: Applicant) => appl.id === this._id);
    if (appl) appl.imageBase64 = base64;
  }

  @Mutation
  SET_SORTING(sorting: result[]) {
    this._sorting = sorting;
  }

}