export interface Container<T> {
  [index: number]: T;

  files?: Container<File>;
  instruments?: Container<Container<LabelValue<string>>>;
}

export interface TypeValue<TypeOfValue> {
  type: string;
  dateStart?: Date;
  value: TypeOfValue;
}

export interface RatingComments {
  user: string;
  comment: string;
  creationDate: Date;
}

export interface RatingElements {
  key: string;
  bezeichnung: string;
  translation: Date;
}

export interface RatingSchema {
  id: string;
  description: string;
  question: string;
  type: string;
  decimals: string;
  max_value: string;
  ranking_elements?: Container<RatingElements>;
}

export interface AnswerRatingSchemaServer {
  question: string;
  answer: string;
  type: string;
}

export interface AnswerRatingSchema {
  id: string;
  type: string;
  value: number | string;
  employmentDate: string;
  probationPeriod: number;
  limitationDate: string;
  text: string;
  dateOne: string;
  dateTwo: string;
  dateThree: string;
  cancelReason: number;
}

export interface Rating {
  hasOpenEvaluation: number;
  points: number;
  answers: AnswerRatingSchema[];
  questions: Container<RatingSchema>;
  comments: RatingComments[];
}

export interface Attachment {
  name: string;
  link: string;
  icon: string;
}

export interface Style {
  background: string;
  color: string;
}

export interface TestContainer {
  tests: Container<MetaContainer>;
  file?: File;
  type: string;
}

export interface MetaContainer {
  meta: Container<LabelValue<string>>;
  file?: File;
  files?: File[];
}

export interface File {
  name: string;
  url: string;
  icon: string;
  allow_download?: boolean;
}

export interface LabelValue<T> {
  label: string;
  style?: Style;
  details?: Details;
  value: T;
}

export interface TestResult {
  name: string;
  ["cut-off"]: string;
  rating: number;
  style: Style;
}

export interface Details {
  test: Container<LabelValue<string>>;
  result: Container<TestResult>;
}

export interface AttachmentContainer {
  allowDownload: boolean;
  files: Container<Attachment>;
  assessment: Container<TestContainer>;
}

export interface User {
  id: string;
  firstname: string;
  lastname: string;
  warningTimeout: number;
  autoLogoutTimeout: number;
}

export interface Position {
  position: string;
  reference_number: string;
}

export interface ApplicantCardDetails {
  gdb: string;
  startDate: string;
  title: string;
  firstname: string;
  lastname: string;
  occupation: string;
  position_id: number;
  sex: string;
  zip: number;
  city: string;
  age: string;
  profileImage: string;
  imageBase64: string;
  note: string;
  evaluation?: string;
  to_evaluate?: string;
}

export interface result {
  key?: string;
  order?: string;
}

export interface ApplicantPersonalDetails extends ApplicantCardDetails {
  birthday: Date;
  birthName: string;
  placeOfBirth: string;
  countryOfBrith: string;
  country: string;
  email: string;
  phoneNumber: TypeValue<string>[];
  photo: string;
  phone: string;
  mobile: string;
  fax: string;
  websiteUrl: string;
  videoUrl: string;
  linkedinUrl: string;
  relativesInTheCompany: any;
  nationality: string;
  religion: string;
  streetAddress: string;
  maritalStatus: string;
  numberOfChildren: string;
  periodSoldier: string;
  videoConference: string;
  noteFromApplicant: string;
  xingUrl: string;
}

export interface Applicant extends ApplicantPersonalDetails {
  id: string;
  rating: Rating;
  _edited_rating: boolean;
  offline: boolean;
  attachments: AttachmentContainer;
  history: TypeValue<string>[];
  development: Container<LabelValue<string>>;
  qualifications: Container<LabelValue<string>>;
  otherData: any;
}

export interface PasswordRules {
  description?: string;
  min_password_length: number;
  max_password_length: number;
  password_contains_lower_case: number;
  password_contains_numbers: number;
  password_contains_upper_case?: number;
  password_contains_special_chars: number;
}

export type LabelRequired = {
  label: string;
  required: boolean;
}
export type PdfOption = {
  options: {
    // eslint-disable-next-line camelcase
    contact_data: LabelRequired,
    // eslint-disable-next-line camelcase
    note_from_applicant: LabelRequired,
    note: LabelRequired,
    history: LabelRequired,
    remarks: LabelRequired,
    tags: LabelRequired,
    career: LabelRequired,
    qualification: LabelRequired,
    other: LabelRequired,
    interview: LabelRequired
  };
  attachments: {
    hogrefe: LabelRequired,
    alpha: LabelRequired,
    uform: LabelRequired,
    geva: LabelRequired,
    checkix: LabelRequired
  };
}

export type PdfOptionPost = {
  options: string[];
  attachments: string[];
}

export enum Questions {
  QUESTION_RANKING_NUMERIC = 100,
  QUESTION_CUSTOM_RANKING_TEXT = 101,

  QUESTION_CUSTOM_TEXT = 2,
  QUESTION_CUSTOM_OPTIONS_ONE_TO_FOUR = 3,
  QUESTION_CUSTOM_OPTIONS_ONE_TO_TEN = 4,
  QUESTION_CUSTOM_YES_OR_NO = 5,
  QUESTION_CUSTOM_NUMERIC = 6,

  QUESTION_RANKING = 10,
  QUESTION_REQUEST_DOCUMENTS = 12,
  QUESTION_INTERVIEW = 13,
  QUESTION_ARRANGE_INTERVIEW_DATE = 14,
  QUESTION_INVITE_TO_TEST = 15,
  QUESTION_EMPLOY = 16,
  QUESTION_ADD_TO_TALENT_POOL = 17,
  QUESTION_CANCEL = 18,
  QUESTION_TOOK_NOTE = 19,
  QUESTION_EMPLOYMENT_APPROVAL = 20,
}

export enum Rated {
  FINISHED = 0,
  NOT_STARTED = 1,
  NOT_FINISHED = 2,
}

export enum TestType {
  ALPHA = "alpha",
  UFORM = "uform",
  HOGREFE = "hogrefe",
  CUTE = "cute",
  GEVA = "geva",
  CHECKIX = "checkix",
}

export const INIT_VALUE = 0;
export const getInitAnswer = (id: string, type: string) => ({
  id,
  type,
  value: INIT_VALUE as number | string,
  employmentDate: "",
  probationPeriod: 0,
  limitationDate: "",
  text: "",
  dateOne: "",
  dateTwo: "",
  dateThree: "",
  cancelReason: 0
});