














































import Widget from "@/mixins/Widget.mixin.vue";
import {
  APPLICANT_DETAIL_GENERAL,
  APPLICANT_DETAIL_OTHER,
  APPLICANT_DETAIL_QUALIFICATION,
  APPLICANT_DETAIL_DEVELOPMENT,
  APPLICANT_DETAIL_TESTS
} from "@/router/names";
import { ApplicantModule } from "@/store";
import { Component, Mixins } from "vue-property-decorator";

@Component({ mixins: [Widget] })
export default class ApplicantDetailNavigation extends Mixins(Widget) {
  ApplicantModule = ApplicantModule;

  navigationList = [
    {
      name: APPLICANT_DETAIL_GENERAL,
      label: "general",
      icon: "account-outline"
    },
    {
      name: APPLICANT_DETAIL_DEVELOPMENT,
      label: "development",
      icon: "timeline-outline"
    },
    {
      name: APPLICANT_DETAIL_QUALIFICATION,
      label: "qualification",
      icon: "seal-variant"
    },
    {
      name: APPLICANT_DETAIL_TESTS,
      label: "tests",
      icon: "timeline-outline"
    },
    {
      name: APPLICANT_DETAIL_OTHER,
      label: "other",
      icon: "timeline-outline"
    }
  ];
}
