






















































// eslint-disable-next-line import/no-cycle
import { ApplicantModule } from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class ApplicantComments extends Vue {
  ApplicantModule = ApplicantModule;
}
