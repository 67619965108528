import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { AppModule } from "@/store";

export const ACCEPT_LANGUAGE = "Accept-Language";
export const ACCESS_CONTROL_ALLOW_ORIGIN = "Access-Control-Allow-Origin";
export const ACCESS_CONTROL_ALLOW_HEADERS = "Access-Control-Allow-Headers";
export const ACCESS_CONTROL_ALLOW_METHODS = "Access-Control-Allow-Methods";

Vue.use(VueAxios, axios);
const initAxios = () => axios.create({
  baseURL: process.env.VUE_APP_CORE_URL,
  timeout: 1000,
  headers: { ACCEPT_LANGUAGE: process.env.VUE_APP_I18N_LOCALE }
});
Vue.axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.status === 401) {
    return AppModule.logout();
  }
  return Promise.reject(error);
});
export default initAxios;
