import { User } from "@/types";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import router from "@/router";

@Module({
  name: "app",
  namespaced: true,
  stateFactory: true
})
export default class AppStore extends VuexModule {
  private _appBarTitle = "";

  private _appBarProminent = false;

  private _appBarColor = "white";

  private _appBarExtensionComponent: any;

  private _appBarImage: any;

  private _appBarHeight = "";

  private _appPage = 1;

  private _appPageSize = 20;

  private _appPageTable = 1;

  private _appPageSizeTable = 20;

  private _appTotalSizeTable = 0;

  private _appUpdateNeeded = false;

  private _isCardView = true;

  private _user: User = <User>{};

  private _loggedIn: boolean = false;

  get loggedIn() {
    return this._loggedIn;
  }

  get user() {
    return this._user;
  }

  get appBarTitle() {
    return this._appBarTitle;
  }

  get appBarProminent() {
    return this._appBarProminent;
  }

  get appBarColor() {
    return this._appBarColor;
  }

  get appBarExtensionComponent() {
    return this._appBarExtensionComponent;
  }

  get appBarImage() {
    return this._appBarImage;
  }

  get appBarHeight() {
    return this._appBarHeight;
  }

  get appPage() {
    return this._appPage;
  }

  get appPageSize() {
    return this._appPageSize;
  }

  get appPageTable() {
    return this._appPageTable;
  }

  get appPageSizeTable() {
    return this._appPageSizeTable;
  }

  get isCardView() {
    return this._isCardView;
  }

  get appUpdateNeeded() {
    return this._appUpdateNeeded;
  }

  @Mutation
  SET_APP_BAR_IMAGE(image: any) {
    this._appBarImage = image;
  }

  @Mutation
  SET_APP_BAR_COLOR(color: string) {
    this._appBarColor = color;
  }

  @Mutation
  SET_APP_BAR_TITLE(title: string) {
    this._appBarTitle = title;
  }

  @Mutation
  SET_APP_BAR_PROMINENT(prominent: boolean) {
    this._appBarProminent = prominent;
  }

  @Mutation
  SET_APP_PAGE(page: number) {
    this._appPage = page;
  }

  @Mutation
  SET_APP_PAGE_TABLE(page: number) {
    this._appPageTable = page;
  }

  @Mutation
  SET_APP_BAR_EXTENSION_COMPONENT(extensionComponent: any) {
    this._appBarExtensionComponent = extensionComponent;
  }

  @Mutation
  SET_APP_PAGESIZE(pageSize: number) {
    this._appPageSize = pageSize;
  }

  @Mutation
  SET_APP_PAGESIZE_TABLE(pageSize: number) {
    this._appPageSizeTable = pageSize;
  }

  @Mutation
  SET_APP_TOTAL_SIZE_TABLE(totalSize: number) {
    this._appTotalSizeTable = totalSize;
  }

  @Mutation
  SET_APP_BAR_HEIGHT(barHeight: string) {
    this._appBarHeight = barHeight;
  }

  @Mutation
  SET_IS_CARD_VIEW(isCardView: boolean) {
    this._isCardView = isCardView;
  }

  @Mutation
  SET_APP_UPDATE_NEEDED(needsUpdate: boolean) {
    this._appUpdateNeeded = needsUpdate;
  }

  @Mutation
  SET_USER(user: User) {
    this._user = user;
  }

  @Mutation
  SET_LOGGED_IN(loggedIn: boolean) {
    this._loggedIn = loggedIn;
  }

  @Action({ rawError: true })
  async logout() {
    this.context.commit("SET_IS_CARD_VIEW", true);
    this.context.commit("SET_USER", <User>{});
    this.context.commit("SET_LOGGED_IN", false);
    router.push("/");
  }
}
