








































































































































































































































































































































































import WidgetMixin from "@/mixins/Widget.mixin.vue";
import { Component, Vue } from "vue-property-decorator";
import { ApplicantModule } from "@/store";

@Component({ mixins: [WidgetMixin] })
export default class ApplicantDetailPersonal extends Vue {
  ApplicantModule = ApplicantModule;

  get showAddress() {
    return this.ApplicantModule.applicant?.streetAddress
      || this.ApplicantModule.applicant?.zip
      || this.ApplicantModule.applicant?.city
      || this.ApplicantModule.applicant?.country;
  }

  get showBirthday() {
    return this.ApplicantModule.applicant?.birthday
      || this.ApplicantModule.applicant?.birthName
      || this.ApplicantModule.applicant?.placeOfBirth
      || this.ApplicantModule.applicant?.countryOfBrith;
  }

  get showAdditionalInfo() {
    return this.ApplicantModule.applicant?.nationality
      || this.ApplicantModule.applicant?.religion
      || this.ApplicantModule.applicant?.maritalStatus
      || this.ApplicantModule.applicant?.numberOfChildren
      || this.ApplicantModule.applicant?.periodSoldier
      || this.ApplicantModule.applicant?.relativesInTheCompany;
  }

  get showPhone() {
    return this.ApplicantModule.applicant?.phone
      || this.ApplicantModule.applicant?.mobile
      || this.ApplicantModule.applicant?.fax;
  }
}
