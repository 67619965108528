
import { ApplicantModule } from "@/store";
import { PdfOption, PdfOptionPost } from "@/types";
import { getBase64FromLink, getDataFromLink } from "@/utils/getImageDataUrl";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class PDFViewerMixin extends Vue {
  pdfUrl = "";

  generateFileUrl = "";

  options: PdfOption = {} as PdfOption;

  postOptions: PdfOptionPost = {} as PdfOptionPost;

  dialog = false;

  postOptionsPdf(isDownload: boolean) {
    // eslint-disable-next-line no-unused-vars
    const postOptions = {
      options: [] as string[],
      attachments: [] as string[]
    };

    const objectKeys = Object.entries(this.options.options);
    const attachmentKeys = Object.entries(this.options.attachments);
    objectKeys.forEach((entry) => {
      if (entry[1].required) postOptions.options.push(entry[0]);
    });
    attachmentKeys.forEach((entry) => {
      if (entry[1].required) postOptions.attachments.push(entry[0]);
    });

    this.postOptions.options = postOptions.options;
    this.postOptions.attachments = postOptions.attachments;
    if (!isDownload) {
      this.getPdf(
        this.generateFileUrl,
        ApplicantModule.applicant!.firstname +
        ApplicantModule.applicant!.lastname,
        postOptions
      );
    } else {
      this.onDownloadButton(
        this.generateFileUrl,
        ApplicantModule.applicant!.firstname +
        ApplicantModule.applicant!.lastname,
        postOptions
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getPdf<T = any>(url: string, filename: string, postBody: T) {
    const postOptions = {
      options: [] as string[],
      attachments: [] as string[]
    };
    const objectKeys = Object.entries(this.options.options);
    const attachmentKeys = Object.entries(this.options.attachments);

    objectKeys.forEach((entry) => {
      if (entry[1].required) {
        postOptions.options.push(entry[0]);
      }
    });
    attachmentKeys.forEach((entry) => {
      if (entry[1].required) {
        postOptions.attachments.push(entry[0]);
      }
    });

    this.postOptions.options = postOptions.options;
    this.postOptions.attachments = postOptions.attachments;

    getDataFromLink(url, postBody).then((result) => {
      this.pdfUrl = this.bufferToBase64(result);
      this.dialog = true;
    });
  }

  bufferToBase64(buf: Uint8Array) {
    var binstr = Array.prototype.map
      .call(buf, function(ch) {
        return String.fromCharCode(ch);
      })
      .join("");
    return btoa(binstr);
  }

  // eslint-disable-next-line class-methods-use-this
  onDownloadButton<T = any>(url: string, filename: string, postBody: T) {
    let linkSource: string = "";
    const downloadLink = document.createElement("a");
    getBase64FromLink(url, postBody).then((result) => {
      linkSource = result;
      downloadLink.href = linkSource;
      downloadLink.download = filename;
      downloadLink.click();
    });
  }
}
