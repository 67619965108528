import { Applicant } from "@/types";
import Vue from "vue";

export function setImageUrl(applicant: Applicant) {
  const key = Vue.axios.defaults.headers.common.authkey;
  const reader = new FileReader();
  if (!applicant.profileImage) return;
  Vue.axios
    .get(applicant.profileImage, {
      responseType: "arraybuffer",
      headers: { authkey: key }
    })
    .then((response) => {
      reader.readAsDataURL(
        new Blob([new Uint8Array(response.data)], {
          type: response.headers["content-type"]
        })
      );
    })
    .catch((error) => {
      if (process.env.NODE_ENV === "development") console.log(error);
    });
  reader.onloadend = () => {
    applicant.imageBase64 = <string>reader.result;
  };
}

export function getBase64FromLink<T = any>(link: string, postBody?: T): Promise<string> {
  const key = Vue.axios.defaults.headers.common.authkey;
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onloadend = () => {
      resolve(<string>reader.result);
    };
    reader.onerror = () => {
      reject(new Error("FileReader failed to readAsDataURL!"));
    };
    if (postBody) {
      Vue.axios
        .post(link, postBody, {
          responseType: "arraybuffer",
          headers: { authkey: key }
        })
        .then((response) => {
          reader.readAsDataURL(new Blob([new Uint8Array(response.data)], {
            type: response.headers["content-type"]
          }));
        }).catch((error) => {
        reject(error);
      });
    } else {
      Vue.axios
        .get(link, {
          responseType: "arraybuffer",
          headers: { authkey: key }
        })
        .then((response) => {
          reader.readAsDataURL(new Blob([new Uint8Array(response.data)], {
            type: response.headers["content-type"]
          }));
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
}

export function getDataFromLink<T = any>(link: string, postBody?: T): Promise<Uint8Array> {
  const key = Vue.axios.defaults.headers.common.authkey;
  return new Promise<Uint8Array>((resolve, reject) => {
    if (postBody) {
      Vue.axios
        .post(link, postBody, {
          responseType: "arraybuffer",
          headers: { authkey: key }
        })
        .then((response) => {
          resolve(new Uint8Array(response.data));
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      Vue.axios
        .get(link, {
          responseType: "arraybuffer",
          headers: { authkey: key }
        })
        .then((response) => {
          resolve(new Uint8Array(response.data));
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
}

export function getBasePDFFromLink<T = any>(link: string, postBody?: T): Promise<string> {
  const key = Vue.axios.defaults.headers.common.authkey;
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onloadend = () => {
      resolve(<string>reader.result);
    };
    reader.onerror = () => {
      reject(new Error("FileReader failed to readAsDataURL!"));
    };

    Vue.axios
      .post(link, postBody, {
        responseType: "arraybuffer",
        headers: { authkey: key }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        reject(error);
      });
  });
}
