









































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RatingSaveDialog extends Vue {
  @Prop() value!: boolean;

  action(decision: boolean) {
    this.$emit("input", false);
    this.$emit("result", decision);
  }
}
