


































































import {
  Container, File, MetaContainer,
} from '@/types';
import { ALPHA, ASSESSMENT_EXTENSION, BASE_URL } from '@/utils/requestUrl';
import { Component, Mixins } from 'vue-property-decorator';
import PDFViewerMixin from '@/mixins/PDFViewerMixin.mixin.vue';
import PdfVue from '@/components/base/PdfVue.vue';

@Component({
  components: {PdfVue  },
  mixins: [PDFViewerMixin],
})
export default class ApplicantTestAlpha
  extends Mixins(PDFViewerMixin) {
container: Container<MetaContainer> = {};

    file: File | undefined;

    async created() {
      const { id } = this.$route.params;
      const res = await this.axios.get<Container<MetaContainer>>(`${BASE_URL + ASSESSMENT_EXTENSION}/${id + ALPHA}`, {withCredentials: true});
      this.container = res.data;
    }
}
