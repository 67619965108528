













import ChangePassword from "@/components/account/ChangePassword.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: { ChangePassword } })
export default class ChangePasswordPage extends Vue {

}
