import { Applicant, ApplicantPersonalDetails, AttachmentContainer } from "@/types";

export function mapApplicants(applicantEntries: Array<any>): Applicant[] {
  const result: Applicant[] = [];
  applicantEntries.forEach((entry: [string, any]) => {
    const resultAppl: Applicant = <Applicant>{};
    resultAppl.id = entry[1].id;
    resultAppl.title = entry[1].title;
    resultAppl.startDate = entry[1].entry_date;
    resultAppl.firstname = entry[1].first_name;
    resultAppl.lastname = entry[1].last_name;
    resultAppl.photo = entry[1].photo;
    resultAppl.age = entry[1].age;
    resultAppl.sex = entry[1].salutation;
    resultAppl.occupation = entry[1].position ? entry[1].position : undefined;
    resultAppl.position_id = Number(entry[1].position_id);
    resultAppl.profileImage = entry[1].photo;
    resultAppl.zip = entry[1].postal_code;
    resultAppl.rating = entry[1].rating ?? ({ hasOpenEvaluation: entry[1].evaluation ? 0 : 1 });
    resultAppl.city = entry[1].place;
    resultAppl.gdb = entry[1].degree_of_disability;
    resultAppl.note = entry[1].note ? entry[1].note : "";
    resultAppl.evaluation = entry[1].evaluation ? entry[1].evaluation : undefined;
    resultAppl.to_evaluate = entry[1].to_evaluate ? entry[1].to_evaluate : undefined;
    result.push(resultAppl);
  });
  return result;
}


export function mapApplicant(entry: any): Applicant {
  const resultAppl: Applicant = <Applicant>{};
  resultAppl.id = entry.id;
  resultAppl.title = entry.title;
  resultAppl.startDate = entry.entry_date;
  resultAppl.firstname = entry.first_name;
  resultAppl.lastname = entry.last_name;
  resultAppl.age = entry.age;
  resultAppl.sex = entry.salutation;
  if (resultAppl.occupation) {
    resultAppl.occupation = entry.position ?? undefined;
  }
  if (entry.position_id) {
    resultAppl.position_id = Number(entry.position_id);
  }
  if (resultAppl.profileImage) {
    resultAppl.profileImage = entry.photo;
  }
  if (resultAppl.zip) {
    resultAppl.zip = entry.postal_code;
  }
  if (resultAppl.rating) {
    resultAppl.rating = entry.rating ?? ({ hasOpenEvaluation: entry.evaluation ? 0 : 1 });
  }
  if (resultAppl.city) {
    resultAppl.city = entry.place;
  }
  if (resultAppl.gdb) {
    resultAppl.gdb = entry.degree_of_disability;
  }
  if (resultAppl.note) {
    resultAppl.note = entry.note ?? "";
  }
  if (resultAppl.evaluation) {
    resultAppl.evaluation = entry.evaluation ?? undefined;
  }
  if (resultAppl.to_evaluate) {
    resultAppl.to_evaluate = entry.to_evaluate ?? undefined;
  }
  return resultAppl;
}

export function mapApplicantContact(applicantDetails: any): ApplicantPersonalDetails {
  const resultAppl: ApplicantPersonalDetails = <Applicant>{};
  resultAppl.birthday = applicantDetails.birth_date;
  resultAppl.birthName = applicantDetails.birth_name;
  resultAppl.firstname = applicantDetails.first_name;
  resultAppl.lastname = applicantDetails.last_name;
  resultAppl.placeOfBirth = applicantDetails.place_of_birth;
  resultAppl.countryOfBrith = applicantDetails.country_of_birth;
  resultAppl.streetAddress = applicantDetails.street;
  resultAppl.country = applicantDetails.country;
  resultAppl.email = applicantDetails.email;
  resultAppl.phone = applicantDetails.phone;
  resultAppl.mobile = applicantDetails.mobile;
  resultAppl.fax = applicantDetails.fax;
  resultAppl.nationality = applicantDetails.nationality;
  resultAppl.religion = applicantDetails.religion;
  resultAppl.maritalStatus = applicantDetails.marital_status;
  resultAppl.numberOfChildren = applicantDetails.number_of_children;
  resultAppl.periodSoldier = applicantDetails.period_soldier;
  resultAppl.relativesInTheCompany = applicantDetails.relatives_in_the_company;
  resultAppl.numberOfChildren = applicantDetails.number_of_children;
  resultAppl.linkedinUrl = applicantDetails.linkedin_url;
  resultAppl.websiteUrl = applicantDetails.website_url;
  resultAppl.videoUrl = applicantDetails.video_url;
  resultAppl.noteFromApplicant = applicantDetails.note_from_applicant;
  resultAppl.videoConference = applicantDetails.link_to_video_conference;
  resultAppl.xingUrl = applicantDetails.xing_url;
  resultAppl.gdb = applicantDetails.degree_of_disability;
  resultAppl.title = applicantDetails.title;
  return resultAppl;
}

export function mapAttachments(attachments: any) {
  const resultAttachments = <AttachmentContainer>{};
  resultAttachments.assessment = attachments.assessment;
  resultAttachments.allowDownload = attachments.allow_download;
  resultAttachments.files = attachments.files;
  return resultAttachments;
}