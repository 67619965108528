























import { AnswerRatingSchemaServer } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ApplicantRatingAnswers extends Vue {
  @Prop() answers!: AnswerRatingSchemaServer[];

  created() {
  }
}

