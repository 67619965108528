


























































































































































































































import WidgetMixin from "@/mixins/Widget.mixin.vue";
import { ApplicantModule } from "@/store";
import { AnswerRatingSchema, Questions } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ mixins: [WidgetMixin] })
export default class ApplicantRating extends Vue {
  @Prop() rules!: any;

  @Prop({ default: false }) ratingFinished!: boolean;

  @Prop() meta!: { question: string, description: string };

  @Prop() answer!: AnswerRatingSchema;

  ApplicantModule = ApplicantModule;

  Questions = Questions;

  created() {
    if ((this.answer.type) as any == Questions.QUESTION_RANKING) {
      console.log(this.answer);
    }
  }

  trialPeriod = [
    this.$t("ratingComponents.hire.trialPeriods.no"),
    this.$t("ratingComponents.hire.trialPeriods.month"),
    this.$t("ratingComponents.hire.trialPeriods.3month"),
    this.$t("ratingComponents.hire.trialPeriods.6month")
  ];

  rejectReason = [
    this.$t("ratingComponents.reject.reasons.reason1"),
    this.$t("ratingComponents.reject.reasons.reason2"),
    this.$t("ratingComponents.reject.reasons.reason3")
  ];

  get error() {
    return this.answer.type === "19" && (!this.answer.value || this.answer.value === 0 || this.answer.value === "0");
  }

  gradeRule(v: number) {
    const str = String(v);
    if (0 < str.trim().length && (Number.isNaN(parseFloat(str)) || Number(str) < 1 || 6 < Number(str))) {
      this.answer.value = (0 < Number(str)) ? 1 : 6;
    }
    return true;
  }

  maxLettersRule(v: number) {
    const str = String(v);
    if (20 < str.length) {
      this.answer.value = str.substring(0, 20);
    }
    return true;
  }

  titleSwitch(type: string, isDescription: boolean) {
    const number = Number(type);
    switch (number) {
      case 2:
      case 101:
        return !isDescription ? this.meta.question : this.meta.description;
      case 10:
        return this.$t("ratingComponents.starRating_10");
      case 13:
        return !isDescription ? this.$t("ratingComponents.interviewHR.title") : this.$t("ratingComponents.interviewHR.description");
      case 14:
        return this.$t("ratingComponents.appointmentHR.title");
      case 15:
        return !isDescription ? this.$t("ratingComponents.acTest.title") : this.$t("ratingComponents.acTest.description");
      case 16:
        return this.$t("ratingComponents.hire.title");
      case 17:
        return this.$t("ratingComponents.talent.title");
      case 18:
        return !isDescription ? this.$t("ratingComponents.reject.title") : this.$t("ratingComponents.reject.reasonDescription");
      case 19:
        return this.$t("ratingComponents.reviewed");
      case 20:
        return !isDescription ? this.$t("ratingComponents.acceptHire.title") : this.$t("ratingComponents.acceptHire.description");
      default:
        return this.meta.question;
    }
  }
}

