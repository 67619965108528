




















































































import { APPLICANT_LIST } from "@/router/names";
import ApplicantSearchBar from "@/components/core/ApplicantSearchBar.vue";
import { Component, Mixins } from "vue-property-decorator";
import Widget from "@/mixins/Widget.mixin.vue";
import { AppModule } from "@/store";
import Logo from "../base/Logo.vue";

@Component({
  components: {
    Logo,
    ApplicantSearchBar
  },
  mixins: [Widget]
})
export default class AppHeader extends Mixins(Widget) {
  AppModule = AppModule;

  get isListPath() {
    return this.$route.name === APPLICANT_LIST;
  }

  goToHome() {
    if (!this.isListPath) {
      this.$router.push("/applicants");
    }
  }
}


