const BASE_URL = process.env.VUE_APP_CORE_URL;
const LOGIN_URL = `${BASE_URL}/user`;
const ADMIN_LOGIN_URL = `${BASE_URL}/auth/admin`;
const APPLICANTS_URL = `${BASE_URL}/applicants`;
const APPLICANTS_CARD_URL = `${BASE_URL}/applicant-cards`;
const APPLICANTS_LIST_URL = `${BASE_URL}/applicant-list`;
const APPLICANTS_SORT = `${BASE_URL}/applicants/sort`;
const PASSWORD_EXTENSION = `${LOGIN_URL}/password`;
const FORGOT_PASSWORD_RULES = `${BASE_URL}/password-rules`;
const FORGOT_PASSWORD = `${BASE_URL}/forgot-password`;
const REVERT_PASSWORD = `${BASE_URL}/password`;
const APPLICANT_URL = `${BASE_URL}/applicant`;
const RANKINGLIST = `${BASE_URL}/ranking-list`;
const CONTACT_EXTENSION = "/contact";
const NOTE_EXTENSION = "/note";
const CAREER_EXTENSION = "/career";
const QUALIFICATION_EXTENSION = "/qualification";
const OTHER_EXTENSION = "/other";
const JOBS_EXTENSION = `${APPLICANTS_URL}/filter/jobs`;
const EVALUATION_EXTENSION = "/evaluation";
const PDF_EXTENSION = "/pdf";
const FIILE_TOTAL_EXTENSION = "/file/total";
const ASSESSMENT_EXTENSION = "/assessment";
const ALPHA = "/alpha";
const UFORM = "/uform";
const HOGREFE = "/hogrefe";
const CUTE = "/cute";
const GEVA = "/geva";
const CHECKIX = "/checkix";

export {
  CONTACT_EXTENSION, APPLICANT_URL, LOGIN_URL, BASE_URL,
  APPLICANTS_URL, PASSWORD_EXTENSION as PASSWORD_URL, ADMIN_LOGIN_URL,
  NOTE_EXTENSION, CAREER_EXTENSION as CAREER, QUALIFICATION_EXTENSION,
  FORGOT_PASSWORD_RULES, REVERT_PASSWORD, FORGOT_PASSWORD, JOBS_EXTENSION,
  OTHER_EXTENSION, EVALUATION_EXTENSION, APPLICANTS_SORT, PDF_EXTENSION, FIILE_TOTAL_EXTENSION,
  ASSESSMENT_EXTENSION, ALPHA, UFORM, HOGREFE, CUTE, GEVA, CHECKIX, RANKINGLIST, APPLICANTS_CARD_URL, APPLICANTS_LIST_URL
};
