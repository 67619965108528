













































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import Snackbar from "@/mixins/Snackbar.mixin.vue";
// eslint-disable-next-line import/no-cycle
import { AppModule } from "@/store";
import { isEmail } from "@/utils/validator";
import { ADMIN_LOGIN_URL, FORGOT_PASSWORD, LOGIN_URL } from "@/utils/requestUrl";
import { APPLICANT_LIST } from "@/router/names";

@Component({ mixins: [Snackbar] })
export default class Login extends Mixins(Snackbar) {
  valid = false;

  dialog = false;

  loading = false;

  password = "";

  passwordRules = [(v: string) => !!v || this.$i18n.t("login.passwordRequired")];

  email: string = "";

  emailRules = [
    (v: string) => !!v || this.$i18n.t("login.emailRequired"),
    (v: string) => isEmail(v) || this.$i18n.t("login.invalidEmail")
  ];

  @Ref() readonly loginPassword!: HTMLElement;

  @Ref() readonly loginEmail!: HTMLElement;

  @Ref() readonly form!: HTMLFormElement;

  created() {
    this.checkRoute();
    this.axios.defaults.withCredentials = true;
  }

  /**
   * @description Check route contains token and id param from admin.
   * This is only for admin login.
   */
  async checkRoute() {
    const { token, id } = this.$route.query;
    if (token && id) {
      this.loading = true;

      try {
        const result = await this.axios.post(ADMIN_LOGIN_URL, {
          token,
          id
        }, { withCredentials: true });
        this.successfullyLoggedIn(result);
      } catch (e) {
        if (this.axios.isAxiosError(e)) {
          this.loginErrorHandling(e);
        }
      }
    }
  }

  validateLoginForm() {
    return this.form.validate();
  }

  /**
   * @method: login
   * @description: Check login form and send login credentials to server.
   * On success this.successfullyLoggedIn is triggered
   * */
  async login() {
    if (this.validateLoginForm()) {
      this.loading = true;
      try {
        const result = await this.axios.post(LOGIN_URL, {
          username: this.email,
          password: this.password
        }, { withCredentials: true });
        this.successfullyLoggedIn(result);
      } catch (e) {
        if (this.axios.isAxiosError(e)) {
          this.loginErrorHandling(e);
        }
      }
    }
  }

  /**
   * @param e The AxiosError from login request
   *
   * Fired on errors of login request.
   * Delegates error handling to mixin Snackbar.
   * You will need a v-snackbar tag in vue-html to use the mixin Snackbar
   */
  loginErrorHandling(e: AxiosError) {
    this.loginStatus = e.response?.status;
    this.snackbar = true;
    this.loading = false;

    // email or password is wrong
    if (this.loginStatus === 400 && e.response?.data.message) {
      this.snackbarHandler(400, (e.response.data.message as string).replace("<br>", ""));
    } else {
      this.snackbarHandler(500, this.$t("login.unknownError"));
    }
  }

  /**
   * @method forgottenPassword sends request for an email to reset the users password and also handles
   * the errors or success with a message to the Snackbar
   */
  forgottenPassword() {
    this.axios.post(FORGOT_PASSWORD, { username: this.email }, { withCredentials: true }).then(() => {
      this.snackbarMessage = "Link für das Zurücksetzen des Passworts wurde an ihre Email Adresse verschickt!";
      this.dialog = true;
    })
      .catch((error: AxiosError) => {
        if (error.response?.data && error.response.data.message) {
          this.snackbarMessage = error.response.data.message;
        } else {
          this.snackbarMessage = "Es ist ein Fehler aufgetreten!";
        }
        this.dialog = true;
      });
  }

  /**
   * @method successfullyLoggedIn sets the user and logged in status in appStorage
   * and redirects to the applicant list view
   */
  async successfullyLoggedIn(result: AxiosResponse) {
    const { data } = result;
    this.snackbarHandler(200, this.$t("login.successful"));
    await AppModule.SET_USER(data.user);
    await AppModule.SET_LOGGED_IN(true);
    await this.$router.push({ name: APPLICANT_LIST });
  }
}
