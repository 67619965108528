import { Container } from "@/types";

const getArrayOfContainer = <T = any>(container: Container<T>, withKey = false) => {
  const res = Object.values(container) as T[];
  if (!withKey) return res;
  const keys = Object.keys(container);
  return res.map((content: any, index) => {
    content.indexInContainer = keys[index];
    return content;
  }) as T[];
};

const getContainerOfArray = <T>(array: T[]) => {
  const container: Container<T> = {};
  array.forEach((value, index) => {
    container[index] = value;
  });
  return container;
};

class Iterator {
  private current: number = 0;

  private hasNext(container: Container<any>) {
    return !!container[this.current + 1];
  }

  next<S>(container: Container<S>) {
    if (this.hasNext(container)) this.current += 1;
    else return null;
    return container[this.current - 1];
  }

  find<S>(container: Container<S>, filter: (value: S) => boolean) {
    let item = this.next<S>(container);
    while (item) {
      if (item && filter(item)) {
        return item;
      }
      item = this.next<S>(container);
    }

    return undefined;
  }

  reset() {
    this.current = 0;
  }
}

export default { getArrayOfContainer, getContainerOfArray, Iterator };