
import VueI18n from "vue-i18n";
import { Vue, Component } from "vue-property-decorator";

@Component({ components: {} })
export default class Snackbar extends Vue {
  snackbar = false;

  loginStatus: number | undefined = 400;

  snackbarMessage: VueI18n.TranslateResult = "";

  snackbarHandler(state: number, message: VueI18n.TranslateResult) {
    this.snackbar = true;
    this.loginStatus = state;
    this.snackbarMessage = message;
  }
}

